import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./alert.css";
import {
  Layout,
  Input,
  Row,
  Col,
  Button,
  Tooltip,
  Popconfirm,
  Tag,
  Select,
  Card,
  Table,
} from "antd";
import { AlertOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
// import component
import HeaderTop from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";
import AddAlertForm from "../../Component/Forms/AddAlertForm/AddAlertForm";
//actions
import * as AlertActions from "../../actions/alertActions";
import * as MachineActions from "../../actions/machineActions";
import { bindActionCreators } from "redux";
// import images
const onSearch = (value) => console.log(value);
const { Search } = Input;

export const Alert = ({ alertActions, alerts, loading, machines }) => {
  let machineIds = undefined;
  const [machine, setMachine] = useState({});
  const [pageLoaded, setPageLoaded] = useState(false);
  const machineRef = useRef();
  machineRef.current = machine;
  const menu = machines.map((machine) => (
    <Select.Option key={machine.id}>
      <a>{machine.machine_name}</a>
    </Select.Option>
  ));

  const setMachineAndAlerts = (machineId) => {
    alertActions.list({ machineId });
    machineIds = machineId;
    setMachine(machines.find((machine) => machine.id == machineId));
  };

  useEffect(() => {
    if (Object.keys(machine).length === 0 && machines.length > 0) {
      let machineId = machines[0]?.id
      if (!pageLoaded) {
        let machineFound = machines.find(machine => machine.id == localStorage.getItem('machineId'))
        if (machineFound)
          machineId = machineFound.id
      }
      setMachineAndAlerts(machineId);
      if (!pageLoaded)
        setPageLoaded(true)
    }

  }, [machines]);
  //table columns
  const columns = [
    {
      title: "ALERT TYPE",
      dataIndex: "alert_type",
      key: "alert_type",
    },
    {
      title: "STATUS",
      dataIndex: "alert_enabled",
      key: "alert_enabled",
      width: "70%",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Tag
            color={`${record.alert_enabled ? "success" : "error"}`}
            key={record.alert_enabled}
            style={{ marginTop: 5 }}
          >{`${record.alert_enabled ? "ACTIVE" : "IN-ACTIVE"}`}</Tag>
        </div>
      ),
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      width: "10%",
      render: (text, record) => {
        return (
          <div style={{}}>

            <Tooltip title={`Edit`}>
              <EditOutlined
                style={{ color: "#1E6EFE", marginRight: "10px" }}
                type="link"
                key={`edit-${record.id}`}
                onClick={() => {
                  editRecord(record);
                }}
              />
            </Tooltip>

            <Popconfirm
              key={`popconfirm-${record.id}`}
              title="Confirm delete?"
              onConfirm={() => deleteRecord(record)}
            >
              <Tooltip title={`Delete`}>
                <DeleteOutlined
                  style={{ color: "#1E6EFE" }}
                  type="link"
                  key={`delete-${record.id}`}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  //states
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState("New");
  const [currentRecord, setcCurrentRecord] = useState(null);

  //CRUD functions
  const addRecord = () => {
    setcCurrentRecord(null)
    setMode('New')
    setVisible(!visible)
  }
  const editRecord = (record) => {
    setcCurrentRecord(record)
    setMode('Edit')
    setVisible(!visible)
  }

  const deleteRecord = (record) => {
    alertActions.remove({ alertId: record.id }, { machineId: machine.id })
  }

  const handleSave = (data) => {
    if (mode == 'New')
      alertActions.create(data, { machineId: machine.id });
    else
      alertActions.update(data, { machineId: machine.id });
    setVisible(false)
  }

  return (
    <div>
      <Layout>
        <HeaderTop />
      </Layout>
      <Layout>
        <Sidebar />
        <div
          style={{ width: "100%" }}
          className="site-layout responsive-margin-60"
        >
          <Layout
            className="site-layout-background"
            style={{
              margin: "24px 16px 0px 16px",
              background: "none",
            }}
          >
            <Row
              style={{
                width: "98%",
                paddingLeft: "2%",
                paddingRight: "0px",
              }}
            >
              <Col span={12}>
                <h2 className="heading-small">ALERTS</h2>
              </Col>
              <Col span={12}>
                <Button
                  className=" right-align-button"
                  icon={<AlertOutlined />}
                  type="primary"
                  onClick={addRecord}
                >
                  ADD ALERT
                </Button>
              </Col>
              <AddAlertForm
                onCreate={(data) => {
                  handleSave(data);
                }}
                current={machine}
                machines={machines}
                type={mode}
                record={currentRecord && currentRecord}
                visible={visible}
                onCancel={() => {
                  setVisible(false);
                }}
              />
            </Row>
          </Layout>
          <Card
            style={{
              width: "95%",
              margin: "auto",
            }}
            className="w-100"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <label>{`Select Machine`}</label>
              <Select
                placeholder="Select Machine"
                style={{
                  background: "white",
                  width: 238,
                  marginLeft: "10px",
                  border: "1px solid #E6E7E9",
                }}
                value={machine.id}
                onChange={(value) => setMachineAndAlerts(value)}
                bordered={true}
              >
                {menu}
              </Select>
            </div>
            <Table
              loading={loading}
              scroll={{ x: 400 }}
              dataSource={alerts}
              columns={columns}
            />
          </Card>
        </div>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  //console.log(state.alertReducer.list);
  return {
    alerts: state.alertReducer.list,
    loading: state.alertReducer.loading,
    machines: state.machineReducer.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertActions: bindActionCreators(AlertActions, dispatch),
    machineActions: bindActionCreators(MachineActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
