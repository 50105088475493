module.exports = process.env.REACT_APP_ENV == 'local' ?
    {

    } : process.env.REACT_APP_ENV == 'dev' ?
        {

        } :
        /* {
             'apiDomain': 'http://localhost:7071/api',
     'awsconfig': {
         'userPoolId': 'ap-southeast-1_BLva1d942',
         'userPoolWebClientId': '3hpb0h9ii87u3kqbrk3panfsjv',
         'region': 'ap-southeast-1'
     }*/


    {
    'apiDomain': 'https://pdm-backend-api.azurewebsites.net/api',
    'awsconfig': {
        'userPoolId': 'ap-southeast-1_BLva1d942',
        'userPoolWebClientId': '3hpb0h9ii87u3kqbrk3panfsjv',
        'region': 'ap-southeast-1'
    }
}