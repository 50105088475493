import React, {useState} from "react";
import moment from 'moment';
import { connect } from "react-redux";
import "./FFTAnalysis.css";
import "../KurtosisChart/KurtosisChart.css";
import "../VibrationChart/VibrationChartCard.css"
import { Card, Button, Radio,Row } from "antd";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { TableOutlined } from "@ant-design/icons";
import axios from "axios";

export const FFTAnalysis = (props) => {
  const [f_x, hideX] = useState(false);
  const [f_y, hideY] = useState(false);
  const [f_z, hideZ] = useState(false);
  const[x_axis_color,setXAxisColor] = useState('#1909ad');
  const[y_axis_color,setYAxisColor] = useState('#09AD19');
  const[z_axis_color,setZAxisColor] = useState('#0681f3');
  function  RMShideShowX (){
    const nextColor = x_axis_color ==='#c1c1c1'? "#1909ad" : "#c1c1c1";
    hideX(!f_x);
    setXAxisColor(nextColor);
  }
  function  RMShideShowY (){
    hideY(!f_y)
    const nextColor = y_axis_color ==='#c1c1c1'? "#09AD19" : "#c1c1c1"
    setYAxisColor(nextColor);
  }
  function  RMShideShowZ (){
    hideZ(!f_z);
    const nextColor = z_axis_color ==='#c1c1c1'? "#0681f3" : "#c1c1c1"
    setZAxisColor(nextColor);
  }
  return (
    <div className="charttable-card-head">
      <Card
        className="cardTable-header"
        title={`${props.title}`}
        style={{ width: "100%" }}

      >
      <h3
          style={{
            textAlign: "right",
            marginRight: "20px",
            marginTop: "20px",
          }}
        >
          Last Updated: {props.date !=null ?moment(props.date).format("DD/MMM/yyyy h:mm A") : 'N/A'}
        </h3>
        {/*<div className="cardTable-header-button">
          <Radio.Group>
            <Radio.Button className="radio-button-custom" value="large">
              Day
            </Radio.Button>
            <Radio.Button className="radio-button-custom" value="default">
              Week
            </Radio.Button>
            <Radio.Button className="radio-button-custom" value="small">
              Month
            </Radio.Button>
          </Radio.Group>
        </div>*/}
        {/* <VelocityChart name={`RMS SIGNAL`} data={props.dataSource} />
        <VelocityChart name={`ORIGINAL SIGNAL`} data={props.dataSource2} /> */}
        <ResponsiveContainer width='95%' aspect={2.0/1.0} height='100%'>
            <BarChart

            data={props.dataSource}
            margin={{
                top: 60,
                left:props.isSuper

            }}

            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="frequency" type="number" domain={[0, 'dataMax']} style={{ fontSize: 12 }}/>
            <YAxis domain={['auto',('dataMax+0.1')]}/>
            <Tooltip labelFormatter={(label) => (label+' Hz')} formatter={(value) => (parseFloat(value).toFixed(2))} />
            <Legend />
            <Bar dataKey="fft_x" hide={f_x} barSize={5} fill="#1909ad" />
            <Bar dataKey="fft_y" hide={f_y} barSize={10} fill="#09AD19" />
            <Bar dataKey="fft_z" hide={f_z} barSize={10} fill="#0681f3" />

            </BarChart>
        </ResponsiveContainer>
        <Row style={{justifyContent:'center', paddingBottom:20}} >
          <div onClick={RMShideShowX} className="showHideButton">
            <Button style={{background:'none' , border:'none'}}> <span className="x-axis-color" style={{marginRight:'10px' , borderRadius:'15%',background:x_axis_color}}></span> FFT - X</Button>
          </div>
          <div onClick={RMShideShowY} className="showHideButton">
            <Button style={{background:'none' , border:'none'}}> <span className="y-axis-color" style={{marginRight:'10px' , borderRadius:'15%',background:y_axis_color}}></span> FFT - Y</Button>
          </div>
          <div onClick={RMShideShowZ} className="showHideButton">
            <Button style={{background:'none' , border:'none'}}> <span className="z-axis-color" style={{marginRight:'10px' , borderRadius:'15%',background:z_axis_color}}></span> FFT - Z</Button>
          </div>
        </Row>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FFTAnalysis);
