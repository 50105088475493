import React from "react";
import { Result, Button, Typography } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import images
import coloredLogo from "../../assets/images/logo-black.png";

export const Error403 = (props) => {
  return (
    <div>
      <Result
        icon={<img
          src={coloredLogo}
        />}
        title={"Error 403 Unauthorized User"}
        subTitle="You are not Authorized to Access this Page."
        extra={
          <Link style={{ color: "white" }} to="/">
            <Button type="primary">
              Back
          </Button>
          </Link>
        }
      />
      ,
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Error403);
