const initialState = {
    list: {},
    loading: false,
    subscribed: false
}

const activitychatReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LIST_ACTIVITYCHAT":
            return {
                ...state,
                list: action.payload,
                loading: false,
                subscribed: true
            };
        case "SET_LOADING_ACTIVITYCHAT":
            return {
                ...state,
                loading: action.payload,
            };
        case "RESET_LIST_ACTIVITYCHAT":
            if (action.payload) {
                if (state.list[action.payload])
                    delete state.list[action.payload]
            }
            else
                state.list = {}
            return {
                ...state,
                subscribed: false
            };
        default:
            return state;
    }
};

export default activitychatReducer;