import AuthProvider from "../services/AuthProvider";
import { notification } from "antd";
import getRealtimeConnection from "../services/SignalRConnection";
import actions from "./index";
import moduleCustomMapping from "./moduleCustomMapping";
import { update } from '../services/apiInteraction'

const { login, logout, getToken } = AuthProvider;

let onReconnection = async (getState, dispatch) => {
    await getRealtimeConnection();
    Object.keys(actions).forEach((key) => {
        if (getState()[`${key.replace("Actions", "")}Reducer`].subscribed)
            dispatch(actions[key].list());
    });
};

let customModuleMethods = {
    refresh: {
        alert: (getState) => {
            let machineId = getState()[`alertReducer`]?.currentMachineId
            return { machineId }
        }
    },
    add: {
        sensorValue: (list, realtimeRecord) => {
            let record = list.find(
                (record) => record.id == realtimeRecord.machine_id
            );
            if (record)
                record.sensorsData = { ...realtimeRecord, machine_id: undefined };
            return list;
        },
        activitychat: (list, realtimeRecord) => {
            if (Array.isArray(list[realtimeRecord.alert_activity_id]))
                list[realtimeRecord.alert_activity_id].push(realtimeRecord)
            else list[realtimeRecord.alert_activity_id] = [realtimeRecord]
            return list;
        }
    },
};

let onRealtimeMessageReceived = (newMessage, getState, dispatch) => {
    try {
        if (newMessage.module) {
            let modules = moduleCustomMapping[newMessage.module] || [
                newMessage.module,
            ];
            modules.forEach((module) => {
                let subscribedToRealtimeChanges = getState()[`${module}Reducer`]
                    .subscribed;
                let list = getState()[`${module}Reducer`].list;
                if (subscribedToRealtimeChanges && list && newMessage.type) {
                    list = JSON.parse(JSON.stringify(list));
                    switch (newMessage.type) {
                        case "refresh":
                            if (actions[`${module}Actions`]?.list) {
                                let conditions = customModuleMethods["refresh"][module] ? customModuleMethods["refresh"][module](getState) : {}
                                dispatch(actions[`${module}Actions`].list(conditions));
                            }
                            return;
                        case "add":
                            if (newMessage.record) {
                                if (customModuleMethods["add"][module])
                                    list = customModuleMethods["add"][module](
                                        list,
                                        newMessage.record
                                    );
                                else list.push(newMessage.record);
                                showAlertNotifications(newMessage);
                            }
                            break;
                        case "update":
                            if (newMessage.record?.id) {
                                let indexOfRecord = list.findIndex(
                                    (record) => record.id == newMessage.record?.id
                                );
                                if (indexOfRecord > -1)
                                    list[indexOfRecord] = {
                                        ...list.find(
                                            (record) => record.id == newMessage.record?.id
                                        ),
                                        ...newMessage.record,
                                    };
                            }
                            break;
                        case "remove":
                            if (newMessage.record?.id) {
                                let indexOfRecord = list.findIndex(
                                    (record) => record.id == newMessage.record?.id
                                );
                                if (indexOfRecord > -1) list.splice(indexOfRecord, 1);
                            }
                            break;
                        default:
                    }
                    dispatch({ type: `LIST_${module.toUpperCase()}`, payload: list });
                }
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const setLoggedIn = () => {
    return async (dispatch, getState) => {
        let user = await getToken();
        dispatch({ type: "SET_LOGGED_IN", payload: user != false });
        if (user) {
            user = user.payload;
            let connection = await getRealtimeConnection();
            window.addEventListener("online", () =>
                onReconnection(getState, dispatch)
            );
            connection.on("newMessage", (newMessage) => {
                onRealtimeMessageReceived(newMessage, getState, dispatch);
            });
            if (
                Array.isArray(user["cognito:groups"]) &&
                user["cognito:groups"].includes("SuperAdmin")
            )
                dispatch({ type: "SET_SUPER_ADMIN", payload: true });
            else dispatch(actions["roleActions"].get());
            dispatch(actions["machineActions"].list());
            dispatch(actions["alertactivityActions"].list());
        }
    };
};

export const signIn = (username, password) => {
    return async (dispatch) => {
        try {
            let response = await login(username, password);
            if (response.error) throw new Error(response.error);
            dispatch(setLoggedIn());
        } catch (err) {
            notification.error({
                message: "Incorrect Email Or Password.",
            });
        }
    };
};

export const changePassword = (password) => {
    return async () => {
        try {
            await update({ module: 'password', data: { password } })
            notification.success({ message: 'Password Changed Successfully!' })
        }
        catch (err) {
            notification.error({ message: err?.message })
        }
    };
};

export const signOut = () => {
    return async (dispatch) => {
        try {
            let response = await logout();
            if (response.error) throw new Error(response.error);
            dispatch({ type: "USER_LOGOUT" });
            dispatch(setLoggedIn());
        } catch (err) {
            notification.error({
                message: "Error Logging Out",
                description: err.message,
            });
        }
    };
};

let showAlertNotifications = (newMessage) => {
    switch (newMessage?.module) {
        case "alertactivity":
            const machine_alert = newMessage?.record?.machine_alert?.alert;
            const { to, from, duration } = machine_alert.alert_settings;
            notification.info({
                message: "Machine Alert Recieved",
                description: `Machine ${newMessage?.record?.machine_id} Alert: ${machine_alert?.alert_type} is in range ${from} - ${to} for ${duration} minutes.`,
            });
            break;
        default:
    }
};
