import React, { useEffect, useState, useRef, state } from "react";
import { connect } from "react-redux";
import "./machines.css";
import { Layout, Row, Col, Menu, Dropdown, Card, Popover, Button, Select, DatePicker, Radio, } from "antd";
import { Link } from "react-router-dom";
// import component
import HeaderTop from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";
import TempChartCard from "../../Component/ChartCard/TempChart/TempChartCard";
import VibrationChartCard from "../../Component/ChartCard/VibrationChart/VibrationChartCard";
import moment from "moment-timezone"
import moments from "moment";

import {
    DownOutlined,
    EditOutlined,
    CopyOutlined,
    DeleteOutlined,
    HeartOutlined,
    ThunderboltOutlined,
    ShrinkOutlined,
    WarningOutlined,
    AlertFilled,
    WarningFilled,
} from "@ant-design/icons";

// Import images
import CompressorImage from "../../assets/images/compressor.PNG";
import RightSidebar from "../../Component/rightSidebar/rightSidebar";
import { bindActionCreators } from "redux";
import * as MachineActions from "../../actions/machineActions";
//import * as TeamActions from "../../actions/teamActions";
import * as MachineKpisActions from "../../actions/machinekpisActions";
import * as AlertActions from "../../actions/alertActions";
import * as SignalRActions from "../../actions/signalRActions";
import activityLog from "../../Component/ActivityLog/activityLog";
import * as AuthActions from "../../actions/authActions";
import { KurtosisChart } from "../../Component/ChartCard/KurtosisChart/KurtosisChart";
import { P2PChart } from "../../Component/ChartCard/P2PChart/P2PChart";
import { Z2PChart } from "../../Component/ChartCard/Z2PChart/Z2PChart";
import { FFTAnalysis } from "../../Component/ChartCard/FFTAnalysis/FFTAnalysis";

// RMS Vibrations


// Chart Data

//const arrVib =[]
//const arrKurt=[]
//const arrP2P=[]
//const arrZ2P=[]

// Chart Data

export const Machines = ({
    machines,
    teams,
    //teamActions,
    machinekpisActions,
    signalRActions,
    fetchDataActions,
    alertActions,
    kpis,
    fft,
    machineStatus,
    alertActivities,
    state,
    fft_updated,
    isSuperAdmin,
}) => {
    const [preddata,setPredData]=useState('')
    //const [preddata, setPredData] = useState([])
    const [arrvar, setStateTemp] = useState([])
    const [arrVib, setStateVib] = useState([])
    const [arrKurt, setStateKurt] = useState([])
    const [arrP2P, setStateP2P] = useState([])
    const [arrZ2P, setStateZ2P] = useState([])
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY-MM-DD';
    let prevMonth = new Date();
    let curr = new Date();
    prevMonth.setDate(prevMonth.getDate() - 30);

    const [currentSelectedDate, setCurrentSelectedDate] = useState(moment(new Date()))
    const [dateRange, setDateRange] = useState([moment(prevMonth, dateFormat), moment(new Date(), dateFormat)])
    const [dateRange1, setDateRange1] = useState([moment(prevMonth, dateFormat), moment(new Date(), dateFormat)])
    const [dateRange2, setDateRange2] = useState([moment(prevMonth, dateFormat), moment(new Date(), dateFormat)])
    const [dateRange3, setDateRange3] = useState([moment(prevMonth, dateFormat), moment(new Date(), dateFormat)])
    const [dateRange4, setDateRange4] = useState([moment(prevMonth, dateFormat), moment(new Date(), dateFormat)])
    const [dateRange5, setDateRange5] = useState([moment(prevMonth, dateFormat), moment(new Date(), dateFormat)])


    const [customRange, setCustomRange] = useState([moment(prevMonth, dateFormat), moment(new Date(), dateFormat)])
    const [graphMode, setGraphMode] = useState('Daily')
    const [graphFormat, setGraphFormat] = useState('HH:mm')
    const [pageLoaded, setPageLoaded] = useState(false);
    const [machine, setMachine] = useState({});
    const machineRef = useRef();
    machineRef.current = machine;
    

    // Machines Drop down
    const menu = machines.map((machine) => (
        <Select.Option key={machine.id}>
            <a>{machine.machine_name}</a>
        </Select.Option>
    ));

    const setMachineAndKpis = (machineId, startDate, endDate) => {
        leaveGroups(machine);
        let timezone = 'Asia/Karachi'
        machinekpisActions.list({ machineId, startDate, endDate, timezone });

        //alertActions.list({ machineId });
        setMachine(machines.find((machine) => machine.id == machineId));
        localStorage.setItem("machineId", machineId)


        //console.log(alerts);
    };
    const fetchData = () => {

        let machineIdToFetchData = machine.sync_machine_id;

        fetchDataActions.fetch(machineIdToFetchData);
    }
    const leaveGroups = ({ id, activityChat = false }) => {
        let groups = []
        if (id)
            groups.push({ group: "machinekpis", key: id, standalone: true })
        if (activityChat)
            groups.push({ group: "activitychat", type: "dict" })
        signalRActions.leaveGroup(groups);
    };

    useEffect(() => {
        let startDate = moment(new Date()).startOf('day').toDate()
        let endDate = moment(new Date()).endOf('day').toDate()
        if (Object.keys(machine).length === 0 && machines.length > 0) {
            let machineId = machines[0].id
            let sensorId = machines[0].sensor_node_id
            if (!pageLoaded) {
                let machineFound = machines.find(machine => machine.id == localStorage.getItem('machineId'))
                if (machineFound)
                    machineId = machineFound.id
            }
            setMachineAndKpis(machineId, startDate, endDate);
            setDateRange([moment(startDate, dateFormat), moment(endDate, dateFormat)]);
            setDateRange1([moment(startDate, dateFormat), moment(endDate, dateFormat)]);
            setDateRange2([moment(startDate, dateFormat), moment(endDate, dateFormat)]);
            setDateRange3([moment(startDate, dateFormat), moment(endDate, dateFormat)]);
            setDateRange4([moment(startDate, dateFormat), moment(endDate, dateFormat)]);
            setDateRange5([moment(startDate, dateFormat), moment(endDate, dateFormat)]);
            if (!pageLoaded)
                setPageLoaded(true)
        }
    }, [machines]);
    useEffect(() => {
        if(kpis.length>0)
        updateKpis(dateRange)
        //onChangeRange(dateRange)
    }, [kpis]);
    useEffect(() => {
        //teamActions.list()
        return () => {
            leaveGroups({ ...machineRef.current, activityChat: true });
        };
    }, []);

    useEffect(() => {
        const article = "0.1,0.2,0.3,0.4,0.5,2";
        const requestOptions = {
            method: 'POST',
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/text' },
            body: "0.1, 0.2, 0.3, 0.4, 0.5, 2"
        };
        //fetch('http://testaimodel.herokuapp.com/predict', requestOptions)
          //  .then(response => response.text())
            //.then(response => setPredData(response));
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);



    const updateKpis = (dateRange)=>{
        onChangeRangeTemp(dateRange)
        onChangeRangeVib(dateRange)
        onChangeRangekurt(dateRange)
        onChangeRangeP2P(dateRange)
        onChangeRangeZ2P(dateRange)
    }
    const onChangeRange = (dateRange) => {

        console.log('dateRange ', dateRange);
        //let xyz = alerts;

        setDateRange(dateRange)
        onChangeRangeTemp(dateRange)
        onChangeRangeVib(dateRange)
        onChangeRangekurt(dateRange)
        onChangeRangeP2P(dateRange)
        onChangeRangeZ2P(dateRange)
        let startDate = dateRange[0].startOf('day').toDate();
        let endDate = dateRange[1].endOf('day').toDate();
        var a = moment(startDate);
        var b = moment(endDate);
        let differenceOfDays = b.diff(a, 'days');
        if (differenceOfDays > 0)
            setGraphFormat('YYYY-MM-DD')
        else
            setGraphFormat('HH:mm')

        if (graphMode == "Monthly") {
            startDate = dateRange[0].startOf('month').toDate();
            endDate = dateRange[1].endOf('month').toDate();
        }
        if (graphMode == "Weekly") {
            startDate = dateRange[0].startOf('week').toDate();
            endDate = dateRange[1].endOf('week').toDate();
        }
        // const { selectedShifts } = this.state
        //  this.getAveragePlantData(selectedShifts, dateRange, this.state.graphMode, this.state.selectedLines);
        setMachineAndKpis(machine.id, startDate, endDate)
    };
    const filterTempData = (startDt, endDt, allKpis) => {
        let array = []
        console.log('endDt',endDt)
        console.log("All KPis+   ", allKpis, "  startDt", startDt);
        let j = 0
        for (let i = 0; i < allKpis.length; i++) {
            let date1 = new Date(allKpis[i].time).getTime();
            let date2 = new Date(startDt).getTime();
            let date3 = new Date(endDt).getTime();
            if (date1 >= date2 && date1 <= date3) {
                array.push(allKpis[i]);
                j++;
            }
        }
        setStateTemp(array);

    };
    ///
    const filterVibData = (startDt, endDt, allkpis) => {
        let array = []
        let j = 0
        for (let i = 0; i < allkpis.length; i++) {
            let date1 = new Date(allkpis[i].time).getTime();
            let date2 = new Date(startDt).getTime();
            let date3 = new Date(endDt).getTime();
            if (date1 >= date2 && date1 <= date3) {
                //arrVib[j] = allkpis[i];
                array.push(allkpis[i])
                j++;
            }
        }
        setStateVib(array);
    };
    /////

    const filterKurtData = (startDt, endDt, allkpis) => {
        let array = [];
        let j = 0
        for (let i = 0; i < allkpis.length; i++) {
            let date1 = new Date(allkpis[i].time).getTime();
            let date2 = new Date(startDt).getTime();
            let date3 = new Date(endDt).getTime();
            if (date1 >= date2 && date1 <= date3) {
                array.push(allkpis[i])
                j++;
            }
        }
        setStateKurt(array);

    };
    /////
    const filteP2PData = (startDt, endDt, allkpis) => {
        let array = [];
        let j = 0
        for (let i = 0; i < allkpis.length; i++) {
            let date1 = new Date(allkpis[i].time).getTime();
            let date2 = new Date(startDt).getTime();
            let date3 = new Date(endDt).getTime();
            if (date1 >= date2 && date1 <= date3) {
                array.push(allkpis[i])
                j++;
            }
        }
        setStateP2P(array);
    };

    ////////
    const filterZ2PData = (startDt, endDt, allkpis) => {
        let array = [];
        let j = 0
        for (let i = 0; i < allkpis.length; i++) {
            let date1 = new Date(allkpis[i].time).getTime();
            let date2 = new Date(startDt).getTime();
            let date3 = new Date(endDt).getTime();
            if (date1 >= date2 && date1 <= date3) {
                array.push(allkpis[i]);
                //arrZ2P[j] = allkpis[i];
                j++;
            }
        }
        setStateZ2P(array);
    };
    ///////////
    ///////// arrZ2P = [], arrFFT = []



    //empty array def
    //for loop on allKpis
    //  if .time is in rnge of date

    const onChangeRangeTemp = (dateRange1) => {

        console.log('dateRange ', dateRange1);
        //let xyz = alerts;

        setDateRange1(dateRange1)
        let startDate = dateRange1[0].startOf('day').toDate();
        let endDate = dateRange1[1].endOf('day').toDate();
        var a = moment(startDate);
        var b = moment(endDate);
        let differenceOfDays = b.diff(a, 'days');
        if (differenceOfDays > 0)
            setGraphFormat('YYYY-MM-DD')
        else
            setGraphFormat('HH:mm')

        if (graphMode == "Monthly") {
            startDate = dateRange1[0].startOf('month').toDate();
            endDate = dateRange1[1].endOf('month').toDate();
        }
        if (graphMode == "Weekly") {
            startDate = dateRange1[0].startOf('week').toDate();
            endDate = dateRange1[1].endOf('week').toDate();
        }
        filterTempData(startDate, endDate, kpis)
        // const { selectedShifts } = this.state
        //  this.getAveragePlantData(selectedShifts, dateRange, this.state.graphMode, this.state.selectedLines);
        //setMachineAndKpis(machine.id, startDate, endDate)
    };
    ///// function for viberations
    const onChangeRangeVib = (dateRange2) => {
        console.log('dateRange ', dateRange2);
        //let xyz = alerts;

        setDateRange2(dateRange2)
        let startDate = dateRange2[0].startOf('day').toDate();
        let endDate = dateRange2[1].endOf('day').toDate();
        var a = moment(startDate);
        var b = moment(endDate);
        let differenceOfDays = b.diff(a, 'days');
        if (differenceOfDays > 0)
            setGraphFormat('YYYY-MM-DD')
        else
            setGraphFormat('HH:mm')

        if (graphMode == "Monthly") {
            startDate = dateRange2[0].startOf('month').toDate();
            endDate = dateRange2[1].endOf('month').toDate();
        }
        if (graphMode == "Weekly") {
            startDate = dateRange2[0].startOf('week').toDate();
            endDate = dateRange2[1].endOf('week').toDate();
        }
        filterVibData(startDate, endDate, kpis)
    };
    ///////////function for  kurtosis 
    const onChangeRangekurt = (dateRange3) => {
        console.log('dateRange ', dateRange3);
        //let xyz = alerts;

        setDateRange3(dateRange3)
        let startDate = dateRange3[0].startOf('day').toDate();
        let endDate = dateRange3[1].endOf('day').toDate();
        var a = moment(startDate);
        var b = moment(endDate);
        let differenceOfDays = b.diff(a, 'days');
        if (differenceOfDays > 0)
            setGraphFormat('YYYY-MM-DD')
        else
            setGraphFormat('HH:mm')

        if (graphMode == "Monthly") {
            startDate = dateRange3[0].startOf('month').toDate();
            endDate = dateRange3[1].endOf('month').toDate();
        }
        if (graphMode == "Weekly") {
            startDate = dateRange3[0].startOf('week').toDate();
            endDate = dateRange3[1].endOf('week').toDate();
        }
        filterKurtData(startDate, endDate, kpis)
    };
    ////////////// function for  P2P
    const onChangeRangeP2P = (dateRange4) => {
        console.log('dateRange ', dateRange4);
        //let xyz = alerts;

        setDateRange4(dateRange4)
        let startDate = dateRange4[0].startOf('day').toDate();
        let endDate = dateRange4[1].endOf('day').toDate();
        var a = moment(startDate);
        var b = moment(endDate);
        let differenceOfDays = b.diff(a, 'days');
        if (differenceOfDays > 0)
            setGraphFormat('YYYY-MM-DD')
        else
            setGraphFormat('HH:mm')

        if (graphMode == "Monthly") {
            startDate = dateRange4[0].startOf('month').toDate();
            endDate = dateRange4[1].endOf('month').toDate();
        }
        if (graphMode == "Weekly") {
            startDate = dateRange4[0].startOf('week').toDate();
            endDate = dateRange4[1].endOf('week').toDate();
        }
        filteP2PData(startDate, endDate, kpis)
    };
    ////////// function for Z2P
    const onChangeRangeZ2P = (dateRange5) => {
        console.log('dateRange ', dateRange5);
        //let xyz = alerts;

        setDateRange5(dateRange5)
        let startDate = dateRange5[0].startOf('day').toDate();
        let endDate = dateRange5[1].endOf('day').toDate();
        var a = moment(startDate);
        var b = moment(endDate);
        let differenceOfDays = b.diff(a, 'days');
        if (differenceOfDays > 0)
            setGraphFormat('YYYY-MM-DD')
        else
            setGraphFormat('HH:mm')

        if (graphMode == "Monthly") {
            startDate = dateRange5[0].startOf('month').toDate();
            endDate = dateRange5[1].endOf('month').toDate();
        }
        if (graphMode == "Weekly") {
            startDate = dateRange5[0].startOf('week').toDate();
            endDate = dateRange5[1].endOf('week').toDate();
        }
        filterZ2PData(startDate, endDate, kpis)
    };


    const onChangeGraphMode = (mode) => {
        // let { selectedShifts, dateRange } = this.state
        //let abc = alerts;
        setGraphMode(mode.target.value)
        let endDate = moment(new Date());
        let startDate = moment(new Date());
        if (mode.target.value == "Monthly") {
            startDate.subtract(30, 'days').startOf('day')
        }
        if (mode.target.value == "Weekly") {
            startDate.subtract(7, 'days').startOf('day')
        }
        startDate = startDate.startOf('day').toDate()
        endDate = endDate.toDate()
        // setDateRange([moment(startDate, dateFormat), moment(endDate, dateFormat)])
        // setDateRange1([moment(startDate, dateFormat), moment(endDate, dateFormat)])
        // setDateRange2([moment(startDate, dateFormat), moment(endDate, dateFormat)])
        // setDateRange3([moment(startDate, dateFormat), moment(endDate, dateFormat)])
        // setDateRange4([moment(startDate, dateFormat), moment(endDate, dateFormat)])
        // setDateRange5([moment(startDate, dateFormat), moment(endDate, dateFormat)])
        // setDateRange6([moment(startDate, dateFormat), moment(endDate, dateFormat)])
        setMachineAndKpis(machine.id, startDate, endDate)
    }


    const viewMachineDetails = (
        <div>
            {/* <p class="gcolor">Machine Status: </p> */}
            <p class="gcolor">Model Number: {machine.model_number}</p>
            <p class="gcolor">OEM Name: {machine.oem_name}</p>
        </div>
    );

    return (
        <div>
            <Layout>
                <HeaderTop />
            </Layout>
            <Layout>
                <Sidebar />
                <Layout className="site-layout responsive-margin-60">
                    <Layout
                        className={(isSuperAdmin) ? "site-layout-background inline-div" : "site-layout-background"}
                        style={{
                            margin: "24px 0px",
                            minHeight: 200,
                            background: "none",
                        }}
                    >
                        <Row className={(isSuperAdmin) ? "width-according-to-sidebar-70" : ""}>
                            <Row style={{ width: "100%", backgroundColor: "#f8f8f8" }}>


                                <Col span={4} style={{ marginTop: '20 px' }}>

                                    <Select
                                        placeholder="Select Machine"
                                        style={{

                                            background: "white",
                                            width: 258,
                                            border: "1px solid #E6E7E9",

                                        }}
                                        value={machine.id}
                                        onChange={(value) => setMachineAndKpis(value, moment(dateRange[0]).startOf('day').toDate(), moment(dateRange[1]).toDate())}
                                        bordered={true}
                                    >
                                        {menu}
                                    </Select>

                                </Col>

                                <Row style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    // border: "1px solid #E6E7E9",
                                    marginBottom: '10px',
                                    width: "100%",
                                }}>
                                    {
                                        (graphMode == "Daily") ?
                                            <RangePicker
                                                style={{ maxWidth: 300 }}
                                                showTime={false}
                                                value={dateRange}
                                                picker="day"
                                                onChange={dateRange => { onChangeRange(dateRange) }}
                                            />
                                            :
                                            (graphMode == "Monthly") ?
                                                <RangePicker
                                                    style={{ maxWidth: 250 }}
                                                    showTime={false}
                                                    picker="month"
                                                    value={dateRange}
                                                    onChange={dateRange => { onChangeRange(dateRange) }}
                                                />
                                                :
                                                <RangePicker
                                                    style={{ maxWidth: 250 }}
                                                    showTime={false}
                                                    picker="week"
                                                    value={dateRange}
                                                    onChange={dateRange => { onChangeRange(dateRange) }}
                                                />
                                    }
                                    <Radio.Group
                                        style={{ marginLeft: 5, visibility: 'hidden', display: 'none' }}
                                        value={graphMode}
                                        onChange={(date) => onChangeGraphMode(date)}
                                    >
                                        <Radio.Button value={"Daily"}>
                                            Daily
                                        </Radio.Button>
                                        <Radio.Button value={"Weekly"}>
                                            Weekly
                                        </Radio.Button>
                                        <Radio.Button value={"Monthly"}>
                                            Monthly
                                        </Radio.Button>
                                    </Radio.Group>

                                    {/* <Button type="dashed" style={{ marginLeft: 5 }}
                  // onClick={this.getReport} 
                  >
                    Export
                  </Button> */}
                                </Row>

                            </Row>
                            <Row style={{ width: "100%" }}>
                                <Card
                                    className="mobile-margin-top"
                                    style={{
                                        width: "100%",
                                        margin: "auto",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <Row>
                                        <Col className="w-100" md={7}>
                                            <img className="machine-images1" src={machine?.image_url ? machine?.image_url : CompressorImage} />
                                        </Col>
                                        <Col className="w-100" md={6}>
                                            <div>
                                                <h2
                                                    className="font-weight-bold m-0"
                                                >
                                                    {machine?.machine_name}
                                                </h2>
                                                <p className="update-machine gcolor m-0">
                                                    {/* Last Update: {Array.isArray(kpis) && kpis.length > 0 && moment(kpis[kpis.length - 1].time).format('D/M hh:mm')} */}
                                                    Last Update: {kpis.length > 0 ? moment(kpis[kpis.length - 1].updated_at).format("dddd [at] h:mm A") : 'No Data in time period selected'}

                                                </p>
                                                <button onClick={fetchData} style={{ cursor: 'pointer', marginTop: '10px', fontWeight: 'bold', fontSize: '18px', background: '#c4c4c4', border: '1px solid black', borderRadius: '10px', padding: '5px', boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.5)' }}><a style={{ color: 'black' }}>Fetch Data Now</a></button>
                                                <div style={{
                                                    marginTop: "30px",
                                                }}
                                                    className="p-0" >
                                                    <Popover bottom content={viewMachineDetails} >
                                                        <p style={{ cursor: 'pointer', color: '#1890ff' }} type="primary">View Details</p>
                                                    </Popover>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col
                                            className="w-100"
                                            style={{ marginTop: "auto", marginBottom: "auto" }}
                                            md={7}
                                        >
                                            <h3 className="rcolor font-20 ">
                                                <AlertFilled
                                                    style={{
                                                        fontSize: "22px",
                                                        marginRight: 15,
                                                    }}
                                                />{preddata}
                                                {Array.isArray(alertActivities) && (alertActivities.filter(log => log?.machine_id == machine?.id && log.alert_urgency == 'Crtical')).length} Critical Alerts
                                            </h3>
                                            <h3
                                                style={{ marginTop: "10px" }}
                                                className="ycolor font-20"
                                            >
                                                <WarningFilled
                                                    style={{
                                                        fontSize: "22px",
                                                        marginRight: 15,
                                                    }}
                                                />

                                                {Array.isArray(alertActivities) && (alertActivities.filter(log => log?.machine_id == machine?.id && log.alert_urgency == 'Faulty')).length} Faulty Alerts
                                            </h3>
                                            <h3>
                                                <ThunderboltOutlined className="centre-align-icon" />

                                                Machine {machineStatus}

                                            </h3>


                                        </Col>
                                        <Col className="w-100" md={4}>
                                            <a>
                                                {/* <DeleteOutlined className="machine-icon" /> */}
                                            </a>
                                            <a>
                                                {/* <CopyOutlined className="machine-icon" /> */}
                                            </a>
                                            <a>
                                                <Link to="/machine-setting"><EditOutlined className="machine-icon"></EditOutlined></Link>
                                            </a>
                                        </Col>
                                    </Row>
                                </Card>
                            </Row>

                            <Row style={{ width: "100%", marginTop: "30px" }}>
                                <Card
                                    className="mobile-margin-top"
                                    style={{
                                        width: "100%",
                                        margin: "auto",
                                        // padding:'10px',
                                        borderRadius: "10px",
                                    }}
                                >
                                    <Row
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <Col className="w-100" md={8}>
                                            <div className="border-right" style={{ height: '100%' }}>
                                                <p className="text-center running-icon">
                                                    <ThunderboltOutlined className="centre-align-icon" />
                                                </p>
                                                <p className="running-counter">{machineStatus}</p>
                                                <p className="running-status">Status</p>
                                            </div>
                                        </Col>
                                        <Col className="w-100" md={8}>
                                            <div className="border-right" >
                                                <p className="text-center health-icon">
                                                    <ShrinkOutlined className="centre-align-icon" />
                                                </p>
                                                <p className="health-counter">
                                                    X:<span style={{ paddingRight: '10%', fontSize: '17px' }}>{kpis.length > 0
                                                        ? kpis[kpis.length - 1].vibration_x
                                                        : "N/A"}{""}</span>
                                                    Y:<span style={{ paddingRight: '10%', fontSize: '17px' }}>{kpis.length > 0
                                                        ? kpis[kpis.length - 1].vibration_y
                                                        : "N/A"}{""}</span>
                                                    Z:<span style={{ paddingRight: '0%', fontSize: '17px' }}>{kpis.length > 0
                                                        ? kpis[kpis.length - 1].vibration_z
                                                        : "N/A"}{""}</span>
                                                    <span style={{ fontSize: '10px', fontStyle: 'italic' }}>(Hz)</span>

                                                </p>
                                                <p className="health-status">RMS Vibrations</p>
                                            </div>
                                        </Col>
                                        <Col className="w-100" md={8}>
                                            {/* <div className="border-right"> */}
                                            <div>
                                                <p className="text-center health-icon">
                                                    <WarningOutlined className="centre-align-icon" />
                                                </p>
                                                <p className="health-counter">
                                                    {kpis.length > 0
                                                        ? kpis[kpis.length - 1].temperature
                                                        : "N/A"}
                                                    &#176;
                                                </p>

                                                <p className="health-status">Temperature</p>

                                            </div>
                                        </Col>
                                        {/* <Col className="w-100 mt-1" md={6}>
                                            <div className="">
                                                <p className="text-center health-icon"><HeartOutlined className="centre-align-icon" /></p>
                                                <p className="health-counter">8</p>
                                                <p className="health-status" >Health Score</p>
                                            </div>
                                        </Col> */}
                                    </Row>
                                </Card>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "40px" }}>


                                <RangePicker
                                    style={{ maxWidth: 250, marginLeft: "645px" }}
                                    showTime={false}
                                    picker="day"
                                    value={dateRange1}
                                    disabledDate={(current) => {
                                        return current && current < moment(curr, "YYYY-MM-DD");
                                    }}
                                    onChange={dateRange1 => { onChangeRangeTemp(dateRange1) }}

                                />
                                <TempChartCard
                                    style={{ width: "100%" }}
                                    title={"Temperature"}
                                    dataSource={arrvar.map((arrvar) => ({
                                        time: new Date(arrvar.time).getTime(),
                                        temperature: arrvar.temperature,
                                        isAlertRegion: arrvar.is_alert_region,
                                        alarmtemperatureto: arrvar.alarmtemperatureto,
                                        alarmtemperaturefrom: arrvar.alarmtemperaturefrom,
                                    }))}
                                    mode={graphFormat}
                                />
                            </Row>

                            <Row style={{ width: "100%", marginTop: "40px" }}>
                                <RangePicker
                                    style={{ maxWidth: 250, marginLeft: "645px" }}
                                    showTime={false}
                                    picker="day"
                                    value={dateRange2}
                                    onChange={dateRange2 => { onChangeRangeVib(dateRange2) }}
                                />
                                <VibrationChartCard
                                    isSuper={isSuperAdmin ? 0 : 30}
                                    style={{ width: "100%" }}
                                    title={"RMS Vibration"}
                                    dataSource={arrVib}
                                    mode={graphFormat}
                                />
                            </Row>

                            <Row style={{ width: "100%", marginTop: "40px" }}>
                                <RangePicker
                                    style={{ maxWidth: 250, marginLeft: "645px" }}
                                    showTime={false}
                                    picker="day"
                                    value={dateRange3}
                                    onChange={dateRange3 => { onChangeRangekurt(dateRange3) }}
                                />
                                <KurtosisChart
                                    isSuper={isSuperAdmin ? 0 : 30}
                                    style={{ width: "100%" }}
                                    title={"Kurtosis"}
                                    dataSource={arrKurt}
                                    mode={graphFormat}
                                />
                            </Row>
                            <Row style={{ width: "100%", marginTop: "40px" }}>
                                <RangePicker
                                    style={{ maxWidth: 250, marginLeft: "645px" }}
                                    showTime={false}
                                    picker="day"
                                    value={dateRange4}
                                    onChange={dateRange4 => { onChangeRangeP2P(dateRange4) }}
                                />
                                <P2PChart
                                    isSuper={isSuperAdmin ? 0 : 30}
                                    style={{ width: "90%" }}
                                    title={"P2P"}
                                    dataSource={arrP2P}
                                    mode={graphFormat}
                                />
                            </Row>
                            <Row style={{ width: "100%", marginTop: "40px" }}>
                                <RangePicker
                                    style={{ maxWidth: 250, marginLeft: "645px" }}
                                    showTime={false}
                                    picker="day"
                                    value={dateRange5}
                                    onChange={dateRange5 => { onChangeRangeZ2P(dateRange5) }}
                                />
                                <Z2PChart
                                    isSuper={isSuperAdmin ? 0 : 30}
                                    style={{ width: "100%" }}
                                    title={"Z2P"}
                                    dataSource={arrZ2P}
                                    mode={graphFormat}
                                />
                            </Row>
                            <Row style={{ width: "100%", marginTop: "40px" }}>
                                <FFTAnalysis
                                    isSuper={isSuperAdmin ? 0 : 30}
                                    style={{ width: "100%" }}
                                    title={"FFT Analysis"}
                                    dataSource={fft}
                                    date={fft_updated}
                                />
                            </Row>
                        </Row>
                        {(isSuperAdmin) ? (
                            <Row className="width-according-to-sidebar-30-f">
                                <RightSidebar
                                    teams={teams}
                                    alertActivities={alertActivities
                                        .filter((activity) => activity?.machine_id == machine.id)
                                        .sort(
                                            (a, b) => new Date(b.created_at) - new Date(a.created_at)
                                        )}
                                    style={{ width: "100%" }}
                                />
                            </Row>
                        ) : ''}
                    </Layout>

                </Layout>
            </Layout>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        machines: state.machineReducer.list,
        kpis: (state.machinekpisReducer.list.data ? state.machinekpisReducer.list.data : state.machinekpisReducer.list),
        fft: ((state.machinekpisReducer.list.fft ? state.machinekpisReducer.list.fft : [])),
        machineStatus: (state.machinekpisReducer.list.machineStatus ? state.machinekpisReducer.list.machineStatus : 'Stopped'),
        //alertsData: ((state.machinekpisReducer.list.alertsData ? state.machinekpisReducer.list.alertsData : [])),
        fft_updated: ((state.machinekpisReducer.list.fft_updated ? state.machinekpisReducer.list.fft_updated : 'N/A')),
        teams: state.teamReducer.list,
        alertActivities: state.alertactivityReducer.list,
        isSuperAdmin: state.authReducer.isSuperAdmin
        //alerts: state.alertReducer.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        machineActions: bindActionCreators(MachineActions, dispatch),
        //teamActions: bindActionCreators(TeamActions, dispatch),
        machinekpisActions: bindActionCreators(MachineKpisActions, dispatch),
        fetchDataActions: bindActionCreators(MachineKpisActions, dispatch),
        signalRActions: bindActionCreators(SignalRActions, dispatch),
        alertActions: bindActionCreators(AlertActions, dispatch),
        actions: bindActionCreators(AuthActions, dispatch),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Machines);
