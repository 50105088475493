import React, {useState} from "react";
import { connect } from "react-redux";
import moment from 'moment';

import "./KurtosisChart.css";
import "../VibrationChart/VibrationChartCard.css"
import { Card,Row , Radio, Button } from "antd";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer,Tooltip , Legend } from 'recharts';
import { TableOutlined } from "@ant-design/icons";

export const KurtosisChart = (props) => {
  
  const [xcolor, setXcolor] = useState('#1909ad');
  const [ycolor, setYcolor] = useState('#09AD19');
  const [zcolor, setZcolor] = useState('#0681f3');
  const [k_x, hideX] = useState(false);
  const [k_y, hideY] = useState(false);
  const [k_z, hideZ] = useState(false);
  const [k_a,hideAlert] = useState(true);
  const[x_axis_color,setXAxisColor] = useState('#1909ad');
  const[y_axis_color,setYAxisColor] = useState('#09AD19');
  const[z_axis_color,setZAxisColor] = useState('#0681f3');

  function  RMShideShowX (){
    const nextColor = x_axis_color ==='#c1c1c1'? "#1909ad" : "#c1c1c1";
    hideX(!k_x);
    setXAxisColor(nextColor);
  }
  function  RMShideShowY (){
    hideY(!k_y)
    const nextColor = y_axis_color ==='#c1c1c1'? "#09AD19" : "#c1c1c1"
    setYAxisColor(nextColor);
  }
  function  RMShideShowZ (){
    hideZ(!k_z);
    const nextColor = z_axis_color ==='#c1c1c1'? "#0681f3" : "#c1c1c1"
    setZAxisColor(nextColor);
  }
  function RMShideShowAlerts(){
    hideAlert(!k_a);
  }
  return (
    <div className="charttable-card-head">
      <Card
        className="cardTable-header"
        title={`${props.title}`}
        style={{ width: "100%" }}
      >
       {/* <div className="cardTable-header-button">
          <Radio.Group>
            <Radio.Button className="radio-button-custom" value="large">
              Day
            </Radio.Button>
            <Radio.Button className="radio-button-custom" value="default">
              Week
            </Radio.Button>
            <Radio.Button className="radio-button-custom" value="small">
              Month
            </Radio.Button>
          </Radio.Group>
        </div>*/}
        {/* <VelocityChart name={`RMS SIGNAL`} data={props.dataSource} />
        <VelocityChart name={`ORIGINAL SIGNAL`} data={props.dataSource2} /> */}
        <ResponsiveContainer width={props.dataSource.length>0?'95%':'100%'} aspect={3.0/1.0}>
          {props.dataSource.length>0?(
          <LineChart 
            style={{marginTop:'60px'}}
            // width={full}
            // height={400}
            margin={{ left: props.isSuper}}
            data={props.dataSource}>
            <CartesianGrid vertical={true} strokeDasharray="3 3" />
            <XAxis domain={['auto', 'auto']}
            name='Time'
            style={{ fontSize: 12 }}
            tickFormatter={(unixTime) => moment(unixTime).format('HH:mm')}
            dataKey="time" />
            <YAxis/>
            <Legend />
            <Tooltip labelFormatter={(label) => (moment(label).format("DD/MMM/yyyy h:mm A"))} formatter={(value) => (parseFloat(value).toFixed(2))} />
            
            <Line strokeWidth={3} type="monotone" hide={k_x} dataKey="kurtosis_x" stroke={xcolor} activeDot={{ r: 8 }} dot={{ fill: '#3F99F7', stroke:'#fff', strokeWidth: 2, r: 7, className: "boxShadow" }} />
            <Line strokeWidth={3} type="monotone" hide={k_y} dataKey="kurtosis_y" stroke={ycolor} activeDot={{ r: 8 }}  />
            <Line strokeWidth={3} type="monotone" hide={k_z} dataKey="kurtosis_z" stroke={zcolor}  activeDot={{ r: 8 }}   />      
            <Line type="monotone"  strokeWidth={3} hide = {k_x && k_a} dataKey="alarmkurtosis_xto" stroke={xcolor} strokeDasharray="3 3"/>
            <Line type="monotone"  strokeWidth={3} hide = {k_x && k_a} dataKey="alarmkurtosis_xfrom" stroke={xcolor} strokeDasharray="3 3"/>    
            <Line type="monotone"  strokeWidth={3} hide = {k_y && k_a} dataKey="alarmkurtosis_yto" stroke={ycolor} strokeDasharray="3 3"/>
            <Line type="monotone"  strokeWidth={3} hide = {k_y && k_a} dataKey="alarmkurtosis_yfrom" stroke={ycolor} strokeDasharray="3 3"/>  
            <Line type="monotone"  strokeWidth={3} hide = {k_z && k_a} dataKey="alarmkurtosis_zto" stroke={zcolor} strokeDasharray="3 3"/>
            <Line type="monotone"  strokeWidth={3} hide = {k_z && k_a} dataKey="alarmkurtosis_zfrom" stroke={zcolor} strokeDasharray="3 3"/>  
      
          </LineChart>
          ):<Card style={{ textAlign: 'center' }}
          title="No Data Available (Change Date Range)"
      />}
          {/* <LineChart width={600} height={300} data={props.dataSource}
                margin={{top: 20, right: 30, left: 20, bottom: 10}}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name" height={60} />
          <YAxis/>
          <Tooltip/>
          <Legend />
          <Line type="monotone"  dataKey="pv" stroke="#8884d8"/>
          <Line type="monotone"  strokeDasharray="3 3" dataKey="uv" stroke="red" />
          </LineChart> */}
        </ResponsiveContainer>
        {props.dataSource.length>0?(
        <Row style={{display:'flex',justifyContent:'center', paddingBottom:20}} >
          <div onClick={RMShideShowAlerts} className="showHideButton" style={{marginRight:10}}>
            <Button style={{background:'none' , border:'none'}}> <span className="alerts-color" style={{marginRight:'10px' , borderRadius:'15%'}}></span> Alerts</Button>
          </div>
          <div onClick={RMShideShowX} className="showHideButton">
            <Button style={{background:'none' , border:'none'}}> <span className="x-axis-color" style={{marginRight:'10px' , borderRadius:'15%',background:x_axis_color}}></span> Kurtosis - X</Button>
          </div>
          <div onClick={RMShideShowY} className="showHideButton">
            <Button style={{background:'none' , border:'none'}}> <span className="y-axis-color" style={{marginRight:'10px' , borderRadius:'15%',background:y_axis_color}}></span> Kurtosis - Y</Button>
          </div>
          <div onClick={RMShideShowZ} className="showHideButton">
            <Button style={{background:'none' , border:'none'}}> <span className="z-axis-color" style={{marginRight:'10px' , borderRadius:'15%',background:z_axis_color}}></span> Kurtosis - Z</Button>
          </div>
        </Row>
        ):null}
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KurtosisChart);
