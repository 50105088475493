import React, { useState } from "react";
import { connect } from "react-redux";
import "./formulaCreation.css";
import {
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Card,
  Button,
  Form,
  Input,
} from "antd";
//icon
import { CloseCircleFilled } from "@ant-design/icons";
// import component
import HeaderTop from "../../../Component/Header/Header";
import Sidebar from "../../../Component/Sidebar/Sidebar";
//math js  for evaluation
import { evaluate } from "mathjs";

export const FormulaCreation = (props) => {
  //sensor and values
  const [snapshot, setSnapshot] = useState({
    sensor1: 0,
    sensor2: 0,
    // downtime: 0,
    // primary: 1,
    // secondary: 1,
  });

  //arithematic symbols
  const operations = ["+", "-", "x", "/", "(", ")"];

  //inputs
  const [formula, setFormula] = useState({
    formula1: {
      string: "",
      text: "",
      value: "",
      isCorrect: true,
      isFocused: false,
      label: `Machine`,
    },
    formula2: {
      string: "",
      text: "",
      value: "",
      isCorrect: true,
      isFocused: false,
      label: `Vibration X`,
    },
    formula3: {
      string: "",
      text: "",
      value: "",
      isCorrect: true,
      isFocused: false,
      label: `Vibration Y`,
    },
    formula4: {
      string: "",
      text: "",
      value: "",
      isCorrect: true,
      isFocused: false,
      label: `Vibration`,
    },
    formula5: {
      string: "",
      text: "",
      value: "",
      isCorrect: true,
      isFocused: false,
      label: `RMS`,
    },
    formula6: {
      string: "",
      text: "",
      value: "",
      isCorrect: true,
      isFocused: false,
      label: `FFT`,
    },
  });
  //save data
  const [result, setResult] = useState("");
  //for reference so we can modify
  let modifyFormula = formula;

  const focusHandler = (key) => {
    Object.keys(modifyFormula).map(
      (ind) => (modifyFormula[ind].isFocused = false)
    );
    modifyFormula[key].isFocused = true;
    setFormula(modifyFormula);
    setResult(formula[key].value);
    console.log("modifyFormula", modifyFormula);
  };

  const formulaHandler = (text, isNum = false) => {
    //value will either be a symbol or btn text
    let value = (isNum ? snapshot[text] : text == "x" ? "*" : text) + " ";
    let focusedInput = Object.keys(formula).find(
      (key) => formula[key].isFocused === true
    );
    let formulaValue = modifyFormula[focusedInput].text + value + " ";
    let correctFormula = true;

    if (focusedInput) {
      try {
        value = evaluate(formulaValue);
      } catch (err) {
        if (modifyFormula[focusedInput].string.length) correctFormula = false;
      }

      let equation = modifyFormula[focusedInput].string + text + " ";
      [
        modifyFormula[focusedInput].string,
        modifyFormula[focusedInput].text,
        modifyFormula[focusedInput].isCorrect,
        modifyFormula[focusedInput].value,
      ] = [equation, formulaValue, correctFormula, value];

      setResult(value);
      setFormula(modifyFormula);
    }
  };

  const cancelHandler = (key) => {
    [
      modifyFormula[key].string,
      modifyFormula[key].text,
      modifyFormula[key].isCorrect,
    ] = ["", "", true];

    setResult("");
    setFormula(modifyFormula);
  };

  const [form] = Form.useForm();
  return (
    <div>
      <Layout>
        <HeaderTop />
      </Layout>
      <Layout>
        <Sidebar />
        <Layout className="site-layout responsive-margin-60 ">
          <div style={{ width: "100%" }} className="site-layout">
            <Layout
              className="site-layout-background"
              style={{
                margin: "24px 16px 0px 16px",
                background: "none",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  paddingLeft: "0%",
                  paddingRight: "0px",
                }}
              >
                <h2 className="heading-small">{`FORMULA CREATION`}</h2>
                <Card>
                  <Row>
                    {/* formula for */}
                    <Col sm={12} md={14}>
                      <Row
                        style={{
                          paddingRight: "0px",
                        }}
                      >
                        {/* Input fields */}
                        {Object.keys(formula).map((key) => (
                          <Row
                            style={{
                              width: "100%",
                              margin: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Col
                              sm={6}
                              md={4}
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <label>{formula[key].label}</label>
                            </Col>
                            <Col sm={18} md={20}>
                              <Row
                                style={{
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Col md={20}>
                                  <Input.Group
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-center",
                                    }}
                                  >
                                    <Input
                                      style={{
                                        width: "100%",
                                        borderColor:
                                          formula[key].isFocused &&
                                          formula[key].isCorrect
                                            ? "green"
                                            : formula[key].isFocused &&
                                              !formula[key].isCorrect &&
                                              "red",
                                      }}
                                      onFocus={() => {
                                        focusHandler(`${key}`);
                                      }}
                                      value={formula[key].string}
                                    />
                                    {formula[key].string.length > 0 && (
                                      <Button
                                        style={{ alignItems: "center" }}
                                        icon={<CloseCircleFilled />}
                                        onClick={() => {
                                          cancelHandler(`${key}`);
                                        }}
                                      />
                                    )}
                                  </Input.Group>
                                </Col>
                                <Col md={4}>
                                  {formula[key].isFocused &&
                                    formula[key].isCorrect && (
                                      <Button
                                        style={{ backgroundColor: "lightblue" }}
                                      >
                                        Define
                                      </Button>
                                    )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ))}
                        <Row
                          style={{
                            width: "100%",
                            margin: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Col
                            sm={6}
                            md={4}
                            style={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <label>Result</label>
                          </Col>
                          <Col sm={18} md={20}>
                            <Input style={{ width: "100%" }} value={result} />
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                    {/* input columns */}
                    <Col
                      sm={12}
                      md={10}
                      style={{
                        width: "100%",
                        backgroundColor: "#F4F7FC",
                      }}
                    >
                      <h2
                        className="heading-small"
                        style={{ padding: "5px" }}
                      >{`INPUTS`}</h2>
                      <Row>
                        <Card
                          bordered={false}
                          style={{
                            width: "100%",
                            backgroundColor: "#F4F7FC",
                            padding: "10px",
                          }}
                        >
                          <h3>{`Board Inputs`}</h3>
                          <div>
                            {Object.keys(snapshot).map((key) => (
                              <Button
                                style={{ width: "100%", marginBottom: "5px" }}
                                onClick={() => formulaHandler(key, true)}
                              >
                                {key}
                              </Button>
                            ))}
                          </div>
                        </Card>
                      </Row>
                      <Row>
                        <Card
                          bordered={false}
                          style={{
                            width: "100%",
                            backgroundColor: "#F4F7FC",
                            padding: "10px",
                          }}
                        >
                          <h3>{`Arithematic Symbols`}</h3>
                          <div>
                            <Row
                              style={{
                                justifyContent: "space-between",
                                marginBottom: "5px",
                                width: "100%",
                              }}
                            >
                              {Object.values(operations).map((value) => (
                                <Col md={8}>
                                  <Button
                                    style={{
                                      width: "90%",
                                      marginBottom: "5px",
                                      alignItems: "center",
                                    }}
                                    onClick={() => formulaHandler(value)}
                                  >{`${value}`}</Button>
                                </Col>
                              ))}
                            </Row>
                          </div>
                        </Card>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Layout>
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormulaCreation);
