import { notification } from 'antd';
import { list as listRoles, get as getRole, create as createRole, update as updateRole, remove as removeRole } from '../services/apiInteraction'

const namespace = 'roles'
export const list = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ROLE", payload: true });
            let data = await listRoles({ module: namespace })
            dispatch({ type: "LIST_ROLE", payload: data });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ROLE", payload: true });
            notification.error({ message: err.message })
        }
    };
};

export const get = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ROLE", payload: true });
            let data = await getRole({ module: namespace })
            dispatch({ type: "GET_ROLE", payload: data });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ROLE", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const create = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ROLE", payload: true });
            await createRole({ module: namespace, data })
            notification.success({ message: 'Role Creation Successful' })
            dispatch({ type: "SET_LOADING_ROLE", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ROLE", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const update = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ROLE", payload: true });
            await updateRole({ module: namespace, data })
            notification.success({ message: 'Role Updated Successful' })
            dispatch({ type: "SET_LOADING_ROLE", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ROLE", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const remove = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ROLE", payload: true });
            await removeRole({ module: namespace, data })
            notification.success({ message: 'Role removed Successful' })
            dispatch({ type: "SET_LOADING_ROLE", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ROLE", payload: false });
            notification.error({ message: err.message })
        }
    };
};