import * as authActions from "./authActions";
import * as machineActions from "./machineActions";
import * as userActions from "./userActions";
import * as roleActions from "./roleActions";
import * as machinekpisActions from "./machinekpisActions";
import * as alertactivityActions from "./alertactivityActions";
import * as sensorValueActions from "./sensorValueActions";
import * as activitychat from "./activitychatActions"
import * as alertActions from "./alertActions";
import * as teamActions from "./teamActions";
import * as healthActions from "./healthActions";

const actions = {
    authActions,
    machineActions,
    userActions,
    roleActions,
    machinekpisActions,
    alertactivityActions,
    sensorValueActions,
    activitychat,
    alertActions,
    teamActions,
    healthActions
}

export default actions;