import React, {useState} from "react";
import { Row,Button,Card } from "antd";
import moment from 'moment';

import "./chartStyle.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { connect } from "react-redux";
export const TemperatureChart = (props) => {
  let { data } = props;
  const [alertColor, setAlertColor] = useState('#ff0000');
  const [t_a, hideAlert] = useState(true);
  function RMShideShowAlerts(){
    hideAlert(!t_a)
  }
  const getReferenceAreas = () => {
    let areas = []
    data.forEach((dataPacket, index) => {
      if (dataPacket.isAlertRegion) {
        if (areas.length == 0 || areas[areas.length - 1].x2)
          areas.push({ x1: index })
      }
      else if (areas.length > 0) {
        if (!areas[areas.length - 1].x2)
          areas[areas.length - 1].x2 = index - 1
      }
    })
    return areas
  }
  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <ResponsiveContainer width="100%" height={data.length>0?200:50}>
        {data.length>0 ?(
        <LineChart
          width={700}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="" />
          <XAxis domain={['auto', 'auto']}
            name='Time'
            style={{ fontSize: 12 }}
            tickFormatter={(unixTime) => moment(unixTime).format('HH:mm')}
            dataKey="time" />
          <YAxis dataKey="temperature" />
          <Tooltip labelFormatter={(label) => (moment(label).format("DD/MMM/yyyy h:mm A"))} formatter={(value) => (parseFloat(value).toFixed(2))} />
          <Legend />
          <Line
            strokeWidth={2}
            stroke="#1E6EFE"
            type="monotone"
            dataKey="temperature"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone"  strokeWidth={3} hide={t_a} dataKey="alarmtemperatureto" stroke={alertColor} strokeDasharray="3 3"/>
          <Line type="monotone"  strokeWidth={3} hide={t_a} dataKey="alarmtemperaturefrom" stroke={alertColor} strokeDasharray="3 3"/>    
          {/*
            getReferenceAreas().map(area => (
              area?.x1 == data.length - 1 || area?.x2 - area?.x1 <= 1 ?
                <ReferenceLine x={area?.x1} stroke="red" fill='red' fillOpacity={0.1} />
                :
                <ReferenceArea x1={area?.x1?.toString()} x2={area?.x2?.toString()} stroke="red" fill='red' fillOpacity={0.1} />
            ))*/
          }

        </LineChart>
        ):<Card style={{ textAlign: 'center' }}
        title="No Data Available (Change Date Range)"
    />}
      </ResponsiveContainer>
      {data.length>0 ?(
      <Row style={{display:'flex',justifyContent:'center', paddingBottom:20}} >
          <div onClick={RMShideShowAlerts} className="showHideButton" style={{marginRight:10}}>
            <Button style={{background:'none' , border:'none'}}> <span className="alerts-color" style={{marginRight:'10px' , borderRadius:'15%'}}></span> Alerts</Button>
          </div>

        </Row>):null
        }
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TemperatureChart);
