import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
//CSS
import "./AddCommentForm.css";

//moment library for time , and time format
const Option = Select.Option
const FormItem = Form.Item
const { TextArea } = Input;
const AddCommentForm = ({ type, visible, onCancel, onCreate, record }) => {
  const [form] = Form.useForm();
  const setFormField = (field, value) => {
    form.setFieldsValue({
      [field]: value
    })
  }


  const capabilities = [
    "View Landing Page", "View Machine Page", "Update Machine Settings", "Add Users", "Update Activity Logs", "Add Alerts", "Create Teams"
  ]

  let title = (type == 'Edit') ? 'EDIT ROLE' : 'ADD COMMENT'
  let btnText = (type == 'Edit') ? 'UPDATE' : 'ADD'
  const data = type === 'Edit' ? { ...record } : {};

  const setBaseInfo = () => {
    //on new form
    if (visible && type !== 'Edit') {
      form.resetFields();
    }
    //on edit form
    else if (visible && type === 'Edit') {
      Object.keys(form.getFieldsValue()).forEach(key => {
        let obj = {};
        obj[key] = data[key] || null;
        if (key == 'roleName') {
          obj['roleName'] = data['role_name'] || null;
        }

        form.setFieldsValue(obj);
      });
      form.setFieldsValue({ roleId: data.id })
      // console.log(form.getFieldsValue())
    }
  }

  useEffect(() => {
    setBaseInfo()
  })

  return (

    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        hideRequiredMark={false}
        layout="vertical"
        name="form_in_modal"
        onFinish={() => {
          form
            .validateFields()
            .then((values) => {
              values = { ...values, roleId: data.id }
              console.log(values)
              onCreate(values);
              form.resetFields();
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
          >
        <Form.Item
            name="Comments"
            label="Comments"
        >
            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        
        <Form.Item name="submit">
          <Button type="primary" htmlType="submit" style={{ width: '100%', fontWeight: 'bold', fontSize: '80%', marginBottom: '0px' }}>{btnText}</Button>
        </Form.Item>
      </Form>
    </Modal>

  );
};

export default AddCommentForm;
