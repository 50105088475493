import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./DashboardHome.css";
import { Layout, Menu, Row, Col, Space, Button, Card, Spin } from "antd";

// import component
import HeaderTop from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";
//machine-info holds info of individual machine such as health , status, vibrations and temperature
import MachineInfoCard from "../../Component/machineInfoCard/machineInfoCard";
//generalInfoCard holds general info such as total motors , alerts , faults
import GeneralInfoCard from "../../Component/generalInformationCard/generalInfoCard";

//import forms
import AddMachineForm from "../../Component/Forms/AddMachineForm/AddMachineForm";

//actions
import * as MachineActions from "../../actions/machineActions";
import * as SensorValueActions from "../../actions/sensorValueActions";
import * as SignalRActions from "../../actions/signalRActions";
import { bindActionCreators } from "redux";
import machines from "../machines/machines";

export const DashboardHome = ({
  sensorValueActions,
  sensorValues,
  signalRActions,
  machineActions,
  alertActivities,
  loading
}) => {
  const [visible, setVisible] = useState(false);
  
  useEffect(async () => {
    sensorValueActions.list();
      return () => {
        signalRActions.leaveGroup([{ group: "machinekpis" }]);
     };
  }, []);

  const addMachine = () => {
    setVisible(!visible);
  };
  
  return (
    <div>
      <Layout>
        <HeaderTop />
      </Layout>
      <Layout>
        <Sidebar />
        <Layout className="site-layout responsive-margin-60">
          <Layout
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              minHeight: 200,
              background: "none",
            }}
          >
            <Row>
              <h2 className="font-weight-bold">DASHBOARD</h2>
              <Button
                className="right-align-button"
                type="primary"
                onClick={addMachine}
              >
                Add Machine
              </Button>
            </Row>
            <AddMachineForm
              onCreate={(data) => {
                machineActions.create(data);
                setVisible(false);
              }}
              type="New"
              visible={visible}
              onCancel={() => {
                setVisible(false);
              }}
            />

            <Row>
              <Col sm={24} xs={24} md={8}>
                <GeneralInfoCard
                  type={"motorCount"}
                  count={sensorValues.length}
                />
              </Col>
              <Col sm={24} xs={24} md={8}>
                <GeneralInfoCard 
                    type={"criticalAlert"} 
                    count={
                      Array.isArray(sensorValues) && (sensorValues.filter(machine => machine.isCritical == true)).length
                      // Array.isArray(alertActivities) && (alertActivities.filter(log => log.alert_urgency == 'Crtical')).length
                    }
                       />
              </Col>
              <Col sm={24} xs={24} md={8}>
                <GeneralInfoCard 
                  type={"faultyAlert"} 
                  count=
                    {
                      Array.isArray(sensorValues) && (sensorValues.filter(machine => machine.isFaulty == true)).length
                      // Array.isArray(alertActivities) && (alertActivities.filter(log => log.alert_urgency == 'Faulty')).length
                    }
                />
              </Col>
            </Row>
            <Row
              style={{
                justifyContent: "center",
                marginTop: "x",
                marginTop: "20px",
              }}
            >
              <Col md={24} style={{ width: "98.5%",flex:"none" }}>
                <Card
                  title={
                    <h3 className="font-weight-bold">Machine Performance</h3>
                  }
                  className="mobile-margin-top "
                  style={{
                    borderRadius: "10px",
                    margin: "auto",
                  }}
                >
                  {
                    (loading)?
                    <Spin/>
                    :

                  <Row>
                    
                    {
                    sensorValues.map((key) => (
                      <Col md={6} style={{ padding: "10px", width: "100%" }}>
                        <MachineInfoCard
                          border={(key.isCritical)?"Critical":(key.isFaulty)?"Faulty":"Normal"}
                          machineid={key.id}
                          machineName={key.machine_name}
                          healthScore="6"
                          status={key.status}
                          vibration_x={
                            key.sensorsData
                              ? key.sensorsData.vibration_x
                              : "N/A"
                          }
                          vibration_y={
                            key.sensorsData
                              ? key.sensorsData.vibration_y
                              : "N/A"
                          }
                          vibration_z={
                            key.sensorsData
                              ? key.sensorsData.vibration_z
                              : "N/A"
                          }
                          temperature={
                            key.sensorsData
                              ? key.sensorsData.temperature
                              : "N/A"
                          }
                        />
                      </Col>
                    ))
                    }
                    </Row>
                  }
                </Card>
              </Col>
            </Row>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sensorValues: state.sensorValueReducer.list,
    loading: state.sensorValueReducer.loading,
    alertActivities: state.alertactivityReducer.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    machineActions: bindActionCreators(MachineActions, dispatch),
    sensorValueActions: bindActionCreators(SensorValueActions, dispatch),
    signalRActions: bindActionCreators(SignalRActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHome);
