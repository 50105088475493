const initialState = {
  visible: false,
};

const sideBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_SIDEBAR":
      return {
        ...state,
        visible: action.payload,
      };

    default:
      return state;
  }
};

export default sideBarReducer;
