import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { Spin } from "antd";

// import PAGES
import Login from "../pages/login/login";
import forgetpassword from "../pages/login/forgetPassword";
import DashboardHome from "../pages/Dashboard/DashboardHome";
import Users from "../pages/users/users";
import Roles from "../pages/roles/roles";
import Team from "../pages/team/team";
import Machines from "../pages/machines/machines";
import MachineSettings from "../pages/machineSettings/machineSettings";
import FormulaCreation from "../pages/machines/formulaCreation/formulaCreation";
import Alert from "../pages/alert/alert";
import Activities from "../pages/activities/activities"
import Error404 from "../pages/Results/error404";
import Error403 from "../pages/Results/error403";
import Health from "../pages/health/health"
import * as AuthActions from "../actions/authActions";
import { bindActionCreators } from "redux";
export const Routers = ({ actions, loggedIn, role, isSuperAdmin }) => {
  const [current, setCurrent] = useState({});

  useEffect(async () => {
    actions.setLoggedIn();
    setCurrent(role);
  }, []);

  const checkAuth = (component, redirectTo = "/login", capability = "/") => {
    console.log(role);
    console.log(capability);
    console.log(role.full_name);
    console.log(isSuperAdmin);
    return loggedIn ? (
      isSuperAdmin ? (
        component
      ) : role?.role?.capabilites.includes(capability) ? (
        component
      ) : (
        <Redirect to={redirectTo} />
      )
    ) : (
      <Redirect to={"/login"} />
    );
  };

  return (
    <div>
      {loggedIn == false ? (
        <Router>
          <Switch>
            <Route path="/login" render={() => <Login />} />
            <Route
              path="/forget"
              render={() => <forgetpassword />}
              component={forgetpassword}
            />
            <Route render={() => <Redirect to={"/login"} />} />
          </Switch>
        </Router>
      ) : loggedIn == null ||
        (!isSuperAdmin && role?.role?.capabilites == undefined) ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin color size="large" />
        </div>
      ) : (
        <Router>
          <Switch>
            <Route
              path="/login"
              render={() => (!loggedIn ? <Login /> : <Redirect to={"/"} />)}
            />
            <Route
              path="/forget"
              render={() =>
                !loggedIn ? <forgetpassword /> : <Redirect to={"/"} />
              }
            />
            <Route
              exact
              path="/"
              render={() =>
                checkAuth(<DashboardHome />, "/403", "View Landing Page")
              }
            />
            <Route
              path="/dashboard"
              render={() =>
                checkAuth(<DashboardHome />, "/403", "View Landing Page")
              }
            />
            <Route
              exact
              path="/users"
              render={() => checkAuth(<Users />, "/403", "Add Users")}
            />
            <Route
              exact
              path="/teams"
              render={() => checkAuth(<Team />, "/403", "Create Teams")}
            />
            <Route
              path="/roles"
              render={() => checkAuth(<Roles />, "/403", "Add Users")}
            />
            <Route
              path="/machines"
              render={() =>
                checkAuth(<Machines />, "/403", "View Machine Page")
              }
            />
            <Route
              path="/machine-setting"
              render={() =>
                checkAuth(
                  <MachineSettings />,
                  "/403",
                  "Update Machine Settings"
                )
              }
            />
            <Route
              path="/formulacreation"
              render={() => checkAuth(<FormulaCreation />, "/403")}
            />
            <Route
              path="/alerts"
              render={() => checkAuth(<Alert />, "/403", "Add Alerts")}
            />
            <Route
                path="/health"
                render={() => checkAuth(<Health />, "/403", "Health")}
            />
            <Route
                    path="/activities"
                    render={() => checkAuth(<Activities />, "/403", "Activity Logs")}
            />
            <Route path="/403" render={() => <Error403 />} />
            <Route render={() => <Error404 />} />
          </Switch>
        </Router>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authReducer.loggedIn,
    isSuperAdmin: state.authReducer.isSuperAdmin,
    role: state.roleReducer.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(AuthActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Routers);
