import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Input, Select } from "antd";

//CSS
import "./AddTeamForm.css";

const AddTeamForm = ({ users, type, record, visible, onCancel, onCreate }) => {
  const [form] = Form.useForm();
  const setFormField = (field, value) => {
    form.setFieldsValue({
      [field]: value
    })
  }

  const title = (type == 'Edit') ? 'EDIT TEAM' : 'ADD NEW TEAM'
  const btnText = (type == 'Edit') ? 'UPDATE' : 'ADD'
  const data = type === 'Edit' ? { ...record } : {};

  //function to set intial values for Edit /New Form
  const setBaseInfo = () => {
    //on new form
    if (visible && type !== 'Edit') {
      form.resetFields();
    }
    //on edit form
    else if (visible && type === 'Edit') {
      Object.keys(form.getFieldsValue()).forEach(key => {
        let obj = {};
        obj[key] = data[key] || null;
        if (key == 'teamName') {
          obj['teamName'] = data['team_name'] || null;
        }
        form.setFieldsValue(obj);
      });
    }
  }

  useEffect(() => {
    setBaseInfo()
  })

  //--------------------DATA PASSED FOR CREATE/UPDATE--------------------------------------
  const handleData = (values) => {
    values = (type == 'Edit') ? { ...values, teamId: record.id } : { ...values }
    onCreate(values);
    form.resetFields();
  }

  // const handleItems = (selectedItems) => {
  //   setSelectedItems(selectedItems);
  // };
  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark={true}
        name="form_in_modal"
        onFinish={() => {
          form
            .validateFields()
            .then((values) => {
              handleData(values)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item rules={[
          { min: 2, message: "Name must have minimum 2 characters." },
          { max: 20, message: "String limit exceed" },
          {
            required: true,
            message: 'Please enter Team Name!'
          },

        ]} name="teamName" label="Team Name" >
          <Input />
        </Form.Item>
        {/* <Form.Item
          rules={[
            { min: 8, message: "It must be 8 characters or above." },
            { max: 24, message: "String limit exceed" },
            { required: true, message: "*Required" },
          ]}
          name="assign-group"
        >
          <label>Assign Group</label>
          <Input name="assign-group" />
        </Form.Item> */}
        <Form.Item rules={[
          {
            required: true,
            message: 'Please select atleast one User!',
          },
        ]} name="userIds" label="User">
          <Select placeholder="Select a User"
            style={{
              width: '100%',
              border: '1px solid #E6E7E9'
            }}
            onChange={(value) => setFormField('userIds', value)}
            bordered={true}
            mode="multiple"
            allowClear={true}                    >
            {
              users.map(user => (
                <Select.Option key={user.full_name} value={user.id}>{user.full_name}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>

        <Form.Item name="submit">
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              fontWeight: "bold",
              fontSize: "80%",
              marginBottom: "0px",
            }}
          >
            {btnText}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTeamForm;
