import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
//CSS
import "./AddFindingForm.css";

//moment library for time , and time format
const Option = Select.Option
const FormItem = Form.Item

const AddFindingForm = ({ type, visible, onCancel, onCreate, record }) => {
  const [form] = Form.useForm();
  const setFormField = (field, value) => {
    form.setFieldsValue({
      [field]: value
    })
  }


  const capabilities = [
    "Loose bearings", "Loose mounting brackets", "Liubrication Low", "Custom"
  ]

  let title = (type == 'Edit') ? 'EDIT ROLE' : 'ADD NEW ROLE'
  let btnText = (type == 'Edit') ? 'UPDATE' : 'ADD'
  const data = type === 'Edit' ? { ...record } : {};

  const setBaseInfo = () => {
    //on new form
    /*if (visible && type !== 'Edit') {
      form.resetFields();
    }*/
    //on edit form
    /*else if (visible && type === 'Edit') {
      Object.keys(form.getFieldsValue()).forEach(key => {
        let obj = {};
        obj[key] = data[key] || null;
        if (key == 'roleName') {
          obj['roleName'] = data['role_name'] || null;
        }

        form.setFieldsValue(obj);
      });
      form.setFieldsValue({ roleId: data.id })
      // console.log(form.getFieldsValue())
    }*/
  }

 /* useEffect(() => {
    setBaseInfo()
  })*/

  return (

    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        hideRequiredMark={false}
        layout="vertical"
        name="form_in_modal"
        onFinish={() => {
          form
            .validateFields()
            .then((values) => {
              values = { ...values, roleId: data.id }
              console.log(values)
              onCreate(values);
              form.resetFields();
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        
        <FormItem rules={[
          {
            required: true,
            message: 'Please select a capability!',
          },
        ]} name="capabilites" label="Capabilities">
          {/* <label className="modal-label" className="modal-label">
            <p className="margin-0">Capabilities</p> */}
          <Select
            mode="multiple"
            allowClear={true}
            onChange={(value) => setFormField('capabilites', value)}
            placeholder="Select at least one capability"
            style={{
              width: '100%',
              border: '1px solid #E6E7E9'
            }}
            defaultValue={[]}
          >
            {capabilities.map(capability => (
              <Option key={capability} value={capability}>
                {`${capability}`}
                </Option>
             
            ))}

          </Select>
          {/* </label> */}
        </FormItem>

        <Form.Item name="submit">
          <Button type="primary" htmlType="submit" style={{ width: '100%', fontWeight: 'bold', fontSize: '80%', marginBottom: '0px' }}>{btnText}</Button>
        </Form.Item>
      </Form>
    </Modal>

  );
};

export default AddFindingForm;
