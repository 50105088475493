import React, { useState } from "react";
import { Button, Modal, Form, Input, Radio } from "antd";

//CSS
import "./AddMachineForm.css";

const AddMachineForm = ({ type, visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    const setFormField = (field, value) => {
        form.setFieldsValue({
            [field]: value
        })
    }

    let title = (type == 'Edit') ? 'EDIT MACHINE' : 'ADD NEW MACHINE'
    let btnText = (type == 'Edit') ? 'UPDATE' : 'ADD'


    return (
        <Modal
            visible={visible}
            title={title}
            className="modal-head"
            onCancel={onCancel}
            footer={null}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                className="modal-custom"
                name="form_in_modal"
                onFinish={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            onCreate(values);
                            form.resetFields();
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                initialValues={{
                    modifier: "public",
                }}
            >
                <Form.Item
                    rules={[
                        { min: 2, message: "Machine name must be minimum 2 characters." },
                        { max: 20, message: "String limit exceed" },
                        { required: true, message: "*Required" },
                    ]}
                    name="machineName"
                    label="Machine Name"
                >
                    <Input className="modal-input" />
                </Form.Item>

                <Form.Item
                    rules={[
                        { min: 2, message: "OME must be minimum 2 characters." },
                        { max: 20, message: "String limit exceed" },
                        { required: true, message: "*Required" }
                    ]}
                    name="oemName"
                    label="OEM Name"
                >
                    <Input className="modal-input" />
                </Form.Item>

                <Form.Item
                    rules={[
                        { min: 2, message: "OME must be minimum 2 characters." },
                        { max: 20, message: "String limit exceed" },
                        { required: true, message: "*Required" }
                    ]}
                    name="modelNumber"
                    label="Model Number"
                >
                    <Input className="modal-input" />
                </Form.Item>

                <Form.Item name="submit">
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            width: "100%",
                            fontWeight: "500",
                            fontSize: "80%",
                            marginBottom: "0px",
                        }}
                    >
                        {btnText}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddMachineForm;