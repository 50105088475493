import { apiDomain } from "../config";
import machines from "../pages/machines/machines";
import AuthProvider from "../services/AuthProvider";

const { getToken } = AuthProvider;

const serializeToQueryParams = (obj) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
};

export const list = async ({
    module,
    conditions = {},
    streamRealtimeChanges = true,
}) => {
    conditions.streamRealtimeChanges = streamRealtimeChanges;
    let { token } = await getToken();
    let response = await fetch(
        `${apiDomain}/${module}/list?${serializeToQueryParams(conditions)}`,
        {
            method: "get",
            headers: { "Content-Type": "application/json", authorization: token },
        }
    );
    let status = response.status;
    response = await response.json();
    if (status != 200) throw new Error(response.message || "Request Failed");
    return response;
};

export const create = async ({ module, data, stringifyJson = true, conditions = {} }) => {
    let { token } = await getToken()
    let response = await fetch(`${apiDomain}/${module}/create?${serializeToQueryParams(conditions)}`, {
        method: 'post',
        headers: { 'Authorization': token },
        body: stringifyJson ? JSON.stringify(data) : data
    })
    if (response.status == 200)
        return
    response = await response.json()
    throw new Error(response.message || 'Request Failed')
};

export const get = async ({ module }) => {
    let { token } = await getToken();
    let response = await fetch(`${apiDomain}/${module}/get`, {
        method: "get",
        headers: { "Content-Type": "application/json", Authorization: token },
    });
    let status = response.status;
    response = await response.json();
    if (status != 200) throw new Error(response.message || "Request Failed");
    return response;
};

export const update = async ({ module, data, stringifyJson = true, conditions = {} }) => {
    let { token } = await getToken();
    let response = await fetch(`${apiDomain}/${module}/update?${serializeToQueryParams(conditions)}`, {
        method: "put",
        headers: { Authorization: token },
        body: stringifyJson ? JSON.stringify(data) : data
    });
    if (response.status == 200) return;
    response = await response.json();
    throw new Error(response.message || "Request Failed");
};

export const remove = async ({ module, data }) => {
    let { token } = await getToken();
    let response = await fetch(`${apiDomain}/${module}/delete`, {
        method: "delete",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify(data),
    });
    if (response.status == 200) return;
    response = await response.json();
    throw new Error(response.message || "Request Failed");
};

export const leaveSignalRGroup = async (groups) => {
    let { token } = await getToken();
    fetch(`${apiDomain}/leaveSignalRGroup`, {
        method: "post",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({ groups }),
    });
};

export const fetchSensorData = async ({module,machineId})=>{
    let { token } = await getToken();
    let data ={};
    data.machineId = machineId;
    console.log(data);
    let response = await fetch(`${apiDomain}/${module}/fetch?${serializeToQueryParams(data)}`, {
        method: "post",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify(data),
    });
    if (response.status == 200)
        return
    response = await response.json()
    throw new Error(response.message || 'Request Failed')
}

export const fetchHealthData = async ({ module, machineId }) => {
    let { token } = await getToken();
    let data = {};
    data.machineId = machineId;
    console.log(data);
    let response = await fetch(`${apiDomain}/${module}/fetch?${serializeToQueryParams(data)}`, {
        method: "post",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify(data),
    });
    if (response.status == 200)
        return
    response = await response.json()
    throw new Error(response.message || 'Request Failed')
}