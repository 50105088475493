import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, Space, Typography, Select } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
//CSS
import './AddUserForm.css'
const AddUserForm = ({ machines, type, visible, onCancel, roles, teams, onCreate, record }) => {
    const [form] = Form.useForm();
    const setFormField = (field, value) => {
        form.setFieldsValue({
            [field]: value
        })
    }

    const title = (type == 'Edit') ? 'EDIT USER' : 'ADD NEW USER'
    const btnText = (type == 'Edit') ? 'UPDATE' : 'ADD'
    const data = type === 'Edit' ? { ...record } : {};

    //function to set intial values for Edit /New Form
    const setBaseInfo = () => {
        console.log(data)

        //on new form
        if (visible && type !== 'Edit') {
            form.resetFields();
        }
        //on edit form
        else if (visible && type === 'Edit') {
            Object.keys(form.getFieldsValue()).forEach(key => {
                let obj = {};
                obj[key] = data[key] || null;
                if (key == 'fullName') {
                    obj['fullName'] = data['name'] || null;
                }
                if (key == 'roleId') {
                    obj['roleId'] = data.role.id || null;
                }
                console.log(obj)
                form.setFieldsValue(obj);
            });
        }
    }

    useEffect(() => {
        setBaseInfo()
    }, [visible])

    //--------------------DATA PASSED FOR CREATE/UPDATE--------------------------------------
    const handleData = (values) => {
        values = (type == 'Edit') ? { ...values, userId: record.id } : { ...values }
        onCreate(values);
        form.resetFields();
    }

    return (
        <Modal
            visible={visible}
            title={title}
            onCancel={onCancel}
            footer={null}
        >
            <Form
                form={form}
                layout="vertical"
                hideRequiredMark={false}
                name="form_in_modal"
                onFinish={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            handleData(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
                initialValues={{
                    modifier: 'public',
                }}
            >
                <Form.Item rules={[
                    { min: 2, message: "Name must have minimum 2 characters." },
                    { max: 20, message: "String limit exceed" },
                    {
                        required: true,
                        message: 'Please enter name!'
                    },

                ]} name="fullName" label="Full Name" >
                    <Input />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid Email ID",
                    },
                ]} name="email" label="Email" >
                    <Input disabled={(type == 'Edit') ? true : false} />
                </Form.Item>

                <Form.Item rules={[
                    { required: type === 'New' ? true : false, message: "Password must have a min length of 8 characters including 1 special character, 1 uppercase and 1 digit", pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ },
                ]} name="password" label={<Space>
                    <label>{type == 'New' ? 'Password' : 'Change Password'}</label>
                    {type == 'Edit' && <Typography.Text type="secondary">{` (Optional)`}</Typography.Text>}
                </Space>}  >
                    <Input.Password
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>

                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Please select a role!',
                    },
                ]} name="roleId" label="Role">
                    <Select placeholder="Select a Role"
                        style={{
                            width: '100%',
                            border: '1px solid #E6E7E9'
                        }}
                        onChange={(value) => setFormField('roleId', value)}
                        bordered={true}
                    >
                        {
                            roles.map(role => (
                                <Select.Option value={role.id}>{role.role_name}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Please select a Machine!',
                    },
                ]} name="machineIds" label="Machine">
                    <Select placeholder="Select a Machine"
                        style={{
                            width: '100%',
                            border: '1px solid #E6E7E9'
                        }}
                        onChange={(value) => setFormField('machineIds', value)}
                        bordered={true}
                        mode="multiple"
                        allowClear={true}                    >
                        {
                            machines.map(machine => (
                                <Select.Option value={machine.id}>{machine.machine_name}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="teamIds" label="Teams">
                    <Select placeholder="Select Team"
                        style={{
                            width: '100%',
                            border: '1px solid #E6E7E9'
                        }}
                        onChange={(value) => setFormField('teamIds', value)}
                        bordered={true}
                        mode="multiple"
                        allowClear={true}                    >
                        {
                            teams.map(team => (
                                <Select.Option value={team.id}>{team.team_name}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item name="submit">
                    <Button type="primary" htmlType="submit" style={{ width: '100%', fontWeight: 'bold', fontSize: '80%', marginBottom: '0px' }}>{btnText}</Button>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default AddUserForm;
