import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./auth.css";
import {
  Button,
  Row,
  Typography,
  Col,
  Form,
  Input,
  Checkbox,
  Card,
} from "antd";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
import * as AuthActions from "../actions/authActions";
import { bindActionCreators } from "redux";

const { Title } = Typography;

const Login = ({ authActions }) => {
  const [rememberCheckBox, setRememberCheckBox] = useState(false);
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = async () => {
    try {
      let values = await form.validateFields();
      checkRememberMe(values);
      authActions.signIn(values.email, values.password);
    } catch (info) {}
  };

  const [emailError, setEmailError] = useState("");

  const [emailtxt, setEmailtxt] = useState("");

  const [user, setUser] = useState("");

  // function emailValidation(email) {
  //   let title = email.target.value;
  //   var mailformat =
  //     /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(test)\.com$/g;
  //   if (title.match(mailformat)) {
  //     setEmailError("");
  //   } else {
  //     setEmailError("Invalid Email");
  //   }
  // }
  function checkRememberMe(values) {
    if (rememberCheckBox == false) {
      localStorage.setItem("rememberUserName", values.email);
      localStorage.setItem("rememberUserPassword", values.password);
    }
  }

  const [savedEmail, setSavedEmail] = useState("");
  const [savedPassword, setSavedPassword] = useState("");

  useEffect(() => {
    if (localStorage.getItem("rememberUserName") != null) {
      setSavedEmail(localStorage.getItem("rememberUserName"));
      setSavedPassword(localStorage.getItem("rememberUserPassword"));
    } else {
      setSavedEmail("");
      setSavedPassword("");
    }
  }, []);

  return (
    <div>
      <Row style={{ justifyContent: "center" }} className="loginPage">
        <Card style={{ minHeight: "100vh", paddingTop: "20vh" }}>
          <Row style={{ justifyContent: "center" }}>
            <Title
              style={{ color: "#1e6efe", fontWeight: 500 }}
              className="text-center bcolor"
              level={2}
            >
              Login
            </Title>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Form
              fields={[
                {
                  name: ["email"],
                  value: savedEmail,
                },
                {
                  name: ["password"],
                  value: savedPassword,
                },
              ]}
              form={form}
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              <label
                className="login-page-margin-0"
                style={{ color: "#B0B4C0" }}
              >
                <p>Email</p>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Email is required",
                    },
                  ]}
                >
                  <Input name="email" style={{ width: "300px" }} />
                </Form.Item>
                <p
                  style={{
                    color: "#ff4d4f",
                  }}
                >
                  {emailError}
                </p>
              </label>

              <label style={{ color: "#B0B4C0" }}>
                <p style={{ marginTop: "10px" }}>Password</p>
                <Form.Item
                  value="testtest"
                  name="password"
                  rules={[{ required: true, message: "Password is required " }]}
                >
                  <Input.Password
                    value="testtest"
                    style={{ width: "300px" }}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </label>

              <div className="remember-me">
                <Form.Item
                  {...tailLayout}
                  valuePropName="checked"
                  name="remember"
                >
                  <Checkbox
                    name="rememberMe"
                    onClick={() => setRememberCheckBox(!rememberCheckBox)}
                    style={{ color: "#B0B4C0" }}
                  >
                    Remember me
                  </Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    style={{ width: "300px", height: "30px" }}
                    type="primary"
                    htmlType="submit"
                    disabled={emailError == "" && emailtxt == "" ? false : true}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Row>

          <p style={{ marginTop: "200px" }} className="text-center bluecolor">
            <Link to="/forget">Forget Email or Password</Link>
          </p>
        </Card>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
