import React, { useState } from "react";
import { connect } from "react-redux";
import "./activityLog.css";
import {
  Layout,
  Row,
  Col,
  Card,
  Button,
  Input,
  Collapse,
  Space,
  message,
  Upload,
  Tag,
  Image,
} from "antd";

import { bindActionCreators } from "redux";
import * as ActivityChatActions from "../../actions/activitychatActions";
import * as SignalRActions from "../../actions/signalRActions";
import moment from "moment";

import {
  AudioOutlined,
  PauseOutlined,
  SendOutlined,
  StopOutlined,
  PaperClipOutlined,
  EditOutlined,
} from "@ant-design/icons";
import useRecorder from "./useRecorder";
import AuthProvider from "../../services/AuthProvider";

const { getToken } = AuthProvider;

//   Searchbar contant
const { Search } = Input;
// toggle Div
const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

const sendMessage = <SendOutlined color="white" />;

export const ActivityLog = (props) => {
  const [comment, setComment] = useState("");
  const [recordState, setRecordState] = useState(null);
  const [attachment, setAttachment] = useState(null);
  let [audioURL, isRecording, startRecording, stopRecording, resetRecording] =
    useRecorder();

  //props
  const {
    index,
    type,
    typeName,
    heading,
    userDetails,
    issueOccur,
    findings,
    recommendations,
    activityChatActions,
    alertActivityId,
    signalRActions,
    setActivityModalVisible,
    chat,
    team,
    teamId,
  } = props;
  const activityChat = chat[alertActivityId] || [];
  const handleChangeFile = (e) => {
    if (e.target.files.length > 0) setAttachment(e.target.files[0]);
  };
  const suffix = isRecording ? (
    <PauseOutlined
      onClick={() => stopRecording()}
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  ) : audioURL || attachment ? (
    <StopOutlined
      onClick={() => {
        if (audioURL) resetRecording();
        else if (attachment) setAttachment(null);
      }}
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  ) : (
    <div style={{ flexDirection: "column" }}>
      <label class="custom-file-upload">
        <input
          onChange={handleChangeFile}
          type="file"
          style={{ display: "none" }}
        />
        <PaperClipOutlined
          style={{
            cursor: "pointer",
            display: "inline",
            fontSize: 16,
            color: "#1890ff",
            marginRight: 5,
          }}
        />
      </label>

      <AudioOutlined
        onClick={() => {
          startRecording();
          setComment("");
        }}
        style={{
          fontSize: 16,
          display: "inline",
          color: "#1890ff",
        }}
      />
    </div>
  );

  const onSearch = async (message) => {
    if (!isRecording && (audioURL || message || attachment)) {
      let { payload } = await getToken();
      const formData = new FormData();
      let body = { alertActivityId, type: "text", message };
      if (audioURL) {
        let blob = await fetch(audioURL).then((r) => r.blob());
        formData.append("file", blob);
        body.type = "audio";
        resetRecording();
      } else if (attachment) {
        formData.append("file", attachment);
        body.type = "attachment";
        setAttachment(null);
      }
      Object.keys(body).forEach((key) => formData.append(key, body[key]));
      activityChatActions.create(formData, {
        filename: `${attachment ? "file" : "recording"}_${alertActivityId}_${
          payload.email
        }_${new Date().getTime()}${
          attachment?.name ? `_${attachment?.name}` : ".mp4"
        }`,
      });
      setComment("");
    }
  };

  //defining color
  const HeadingColor = {
    criticalAlert: { color: "#CA2B30" },
    resolved: { color: "#09C38E" },
    observation: { color: "#788090" },
    faultyAlert: { color: "#FFAA00" },
  };

  const color = HeadingColor[type].color;

  // Assigning team
  const assignedTeam = [];
  team.forEach((ele) => {
    assignedTeam.push(
      <p
        className="collaps-content m-0 "
        key={ele.team_id}
      >{`- ${ele.team_name}`}</p>
    );
  });

  //breaking array for jsx
  //findings
  const findingsOptions = [];
  findings.forEach((element) => {
    findingsOptions.push(
      <p className="collaps-content m-0 " key={element}>{`- ${element}`}</p>
    );
  });

  //recommendations
  const recommendationsOptions = [];
  recommendations.forEach((element) => {
    recommendationsOptions.push(
      <p className="collaps-content m-0 " key={element}>{`- ${element}`}</p>
    );
  });

  //comments
  const commentsOptions = [];
  if (activityChat) {
    activityChat.forEach((element) => {
      let attachmentName;
      if (element.type == "attachment") {
        let parts = element.resource_data.split("_");
        if (parts.length > 3) attachmentName = parts.slice(4).join("_");
      }
      commentsOptions.push(
        <div className="collaps-content m-0 " key={element.id}>
          <p
            className="wrap-word"
            style={{
              fontWeight: "bold",
              display: "inline",
              wordWrap: "break-word",
            }}
          >{`${element.user_name}`}</p>
          <p style={{ display: "inline", wordWrap: "break-word" }}>{` (${moment(
            element.time
          ).format("DD/MMM/yyyy h:mm A")})  :`}</p>
          {element.type == "audio" ? (
            <audio
              style={{ height: 26, display: "block" }}
              src={element.resource_data}
              controls
            />
          ) : element.type == "attachment" &&
            element.resource_data.match(/[^/]+(jpg|png|gif|jpeg)$/) ? (
            <Image height={200} width={200} src={element.resource_data} />
          ) : element.type == "attachment" &&
            element.resource_data.match(/[^/]+(mp4|mkv|ogg|webm)$/) ? (
            <video width={200} height={200} controls="controls">
              <source src={element.resource_data} type="video/mp4" />
            </video>
          ) : element.type == "attachment" ? (
            <a
              style={{ display: "block" }}
              href={element.resource_data}
              download
            >
              {attachmentName}
            </a>
          ) : (
            <p style={{ wordWrap: "break-word" }}> {element.resource_data}</p>
          )}
        </div>
      );
    });
  }
  const listChatOrLeaveGroup = (ev) => {
    if (ev) {
      activityChatActions.list({ alertActivityId });
    } else {
      signalRActions.leaveGroup([
        { group: "activitychat", key: alertActivityId, standalone: true },
        true,
      ]);
    }
  };
  return (
    <div>
      <Collapse
        onChange={listChatOrLeaveGroup}
        className="sidebar-collaps"
        accordion
        defaultActiveKey={1}
        expandIconPosition="right"
      >
        <Panel
          header={
            <div>
              <div style={{ float: "right" }}>
                <a
                  className="add-icon"
                  onClick={() => setActivityModalVisible(alertActivityId)}
                >
                  <EditOutlined />
                </a>
              </div>
              <h5 style={{ color: color }} className="sidebar-heading m-0">
                {`${typeName}`}:{" "}
                <span className="sidebar-heading-span">{`${heading}`}</span>
              </h5>
              <p
                style={{ wordWrap: "break-word" }}
                className="date-status"
              >{`${userDetails}`}</p>
            </div>
          }
          key={index}
        >
          <p
            style={{ wordWrap: "break-word" }}
            className="collaps-content"
          >{`${issueOccur}`}</p>

          <h2 className="font-weight-bold m-0">Assigned To</h2>
          {assignedTeam}

          <h2 className="font-weight-bold m-0">Findings</h2>
          {findingsOptions}

          <h2 className="font-weight-bold m-0">Recommended Actions</h2>
          {recommendationsOptions}

          <h2 className="font-weight-bold m-0">Comments</h2>
          {commentsOptions}
          <Space style={{ marginTop: 10 }} direction="vertical">
            <Search
              className="testname"
              onChange={(ev) =>
                !audioURL && !isRecording && !attachment
                  ? setComment(ev.target.value)
                  : ""
              }
              value={comment}
              disabled={isRecording}
              placeholder={
                audioURL || attachment
                  ? ""
                  : isRecording
                  ? "Recording..."
                  : "Enter Comment"
              }
              enterButton={sendMessage}
              size="large"
              suffix={suffix}
              prefix={
                audioURL ? (
                  <audio style={{ height: 26 }} src={audioURL} controls />
                ) : attachment ? (
                  <Tag>{attachment?.name}</Tag>
                ) : null
              }
              onSearch={onSearch}
            />
          </Space>
          {/* <TextArea className="comment" suffix={<FileAddOutlined />} /> */}
        </Panel>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chat: state.activitychatReducer.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activityChatActions: bindActionCreators(ActivityChatActions, dispatch),
    signalRActions: bindActionCreators(SignalRActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);
