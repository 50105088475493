import React from "react";
import { connect } from "react-redux";
import { Row, Col, Card } from "antd";

// CSS
import "./general-info-card.css";

// images
import MotorIcon from "../../assets/images/motor.png";
import CriticalAlert from "../../assets/images/alert.png";
import approvedSignal from "../../assets/images/approved-signal.png";
import warningAlert from "../../assets/images/warning.png";

export const GeneralInfoCard = ({ type, count }) => {
  let Types = {
    motorCount: {
      heading: "Total Motors",
      imgSrc: MotorIcon,
      counterClass: "counter blue",
    },
    criticalAlert: {
      heading: "Critical Alerts",
      imgSrc: CriticalAlert,
      counterClass: "counter red",
    },
    condition: {
      heading: "Good Condition",
      imgSrc: approvedSignal,
      counterClass: "counter green",
    },
    faultyAlert: {
      heading: "Faulty Alerts",
      imgSrc: warningAlert,
      counterClass: "counter orange",
    },
  };

  const { heading, imgSrc, counterClass } = Types[type];

  return (
    <div xs={24}>
      <Card
        className="mobile-margin-top dashboard-card-height dashboard-card-wdth"
        style={{
          width: "95%",
          margin: "auto",
          borderRadius: "10px",
        }}
      >
        <Row
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 5,
            paddingBottom: 5,
          }}
        >
          <Col span={15}>
            <img src={imgSrc} />
            <Row>
              <p className="card-para-heading">{heading}</p>
            </Row>
          </Col>
          <Col className="p-relative" span={9}>
            <span className={counterClass}>{count.toString()}</span>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default GeneralInfoCard;

// export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoCard)
