import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";

import {
  AppstoreOutlined,
  UserOutlined,
  ToolOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  AlertOutlined,
  UsergroupAddOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import * as SideBarActions from "../../actions/sideBarActions";
import * as AuthActions from "../../actions/authActions";
import { bindActionCreators } from "redux";
import "./Sidebar.css";
const { SubMenu } = Menu;

const Sidebar = ({ visible, role, isSuperAdmin, sidebarActions }) => {
  const location = useLocation();
  const checkScreenSize = () => {
    if (window.innerWidth <= 500) {
      sidebarActions.toggleSideBar(true);
    }
  };
  useEffect(() => {
    checkScreenSize();
  }, []);

  return (
    <Layout.Sider
      className="sidebar"
      breakpoint={"lg"}
      collapsedWidth={0}
      trigger={null}
      collapsed={visible}
    >
      <Menu
        defaultSelectedKeys={["/"]}
        defaultOpenKeys={["users-manage", "machine-manage"]}
        selectedKeys={[location.pathname]}
        mode="inline"
      >
        {(isSuperAdmin ||
          role?.role?.capabilites.includes("View Landing Page")) && (
          <Menu.Item to="/" key="/" icon={<AppstoreOutlined />}>
            <Link to="/">Dashboard </Link>
          </Menu.Item>
        )}

        {
          <SubMenu
            inlineCollapsed="false"
            key="users-manage"
            title={`User Management`}
          >
            <Menu.ItemGroup key="/users-manage">
              {(isSuperAdmin ||
                role?.role?.capabilites.includes("Add Users")) && (
                <Menu.Item key="/users" icon={<UserOutlined />}>
                  <Link to="/users"> Users</Link>
                </Menu.Item>
              )}
              {(isSuperAdmin ||
                role?.role?.capabilites.includes("Create Teams")) && (
                <Menu.Item key="/teams" icon={<UsergroupAddOutlined />}>
                  <Link to="/teams"> Team</Link>
                </Menu.Item>
              )}
              {(isSuperAdmin ||
                role?.role?.capabilites.includes("Add Users")) && (
                <Menu.Item key="/roles" icon={<SolutionOutlined />}>
                  <Link to="/roles"> Roles</Link>
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          </SubMenu>
        }
        {
          <SubMenu key="machine-manage" title={`Machine Management`}>
            <Menu.ItemGroup>
              {(isSuperAdmin ||
                role?.role?.capabilites.includes("View Machine Page")) && (
                <Menu.Item icon={<SettingOutlined />} key="/machines">
                  <Link to="/machines"> Machine </Link>
                </Menu.Item>
              )}
              {(isSuperAdmin ||
                role?.role?.capabilites.includes(
                  "Update Machine Settings"
                )) && (
                <Menu.Item icon={<ToolOutlined />} key="/machine-setting">
                  <Link to="/machine-setting"> Machine Setting</Link>
                </Menu.Item>
              )}
              {isSuperAdmin && (
                <Menu.Item icon={<PlusCircleOutlined />} key="/formulacreation">
                  <Link to="/formulacreation"> Formula Creation</Link>
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          </SubMenu>
        }
        {(isSuperAdmin || role?.role?.capabilites.includes("Add Alerts")) && (
          <Menu.Item key="/alerts" icon={<AlertOutlined />}>
            <Link to="/alerts">Alerts</Link>
          </Menu.Item>
       )}
        {(
            <Menu.Item key="/activities" icon={<AlertOutlined />}>
                <Link to="/activities">Activity Logs</Link>
            </Menu.Item>
        )}
        {isSuperAdmin &&(
            <Menu.Item key="/health" icon={<AlertOutlined />}>
                <Link to="/health">Machine Health</Link>
            </Menu.Item>
        )}
         
      </Menu>
    </Layout.Sider>
  );
};
const mapStateToProps = (state) => {
  return {
    visible: state.sideBarReducer.visible,
    loggedIn: state.authReducer.loggedIn,
    isSuperAdmin: state.authReducer.isSuperAdmin,
    role: state.roleReducer.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(AuthActions, dispatch),
    sidebarActions: bindActionCreators(SideBarActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);