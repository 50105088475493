import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import "./team.css";

import { Layout, Input, Row, Col, Button, Card, Table, Tag, Popconfirm, Tooltip } from 'antd';

import {
    AlertOutlined, DeleteOutlined, EditOutlined,
} from '@ant-design/icons';


// import component 
import HeaderTop from '../../Component/Header/Header';
import Sidebar from '../../Component/Sidebar/Sidebar';
import StandardTable from '../../Component/Table/table'
import AddTeamForm from '../../Component/Forms/AddTeamForm/AddTeamForm'

//actions
import * as UserActions from '../../actions/userActions'
import * as TeamActions from '../../actions/teamActions'
import * as SignalRActions from '../../actions/signalRActions'
import { bindActionCreators } from "redux";


// import images
const onSearch = (value) => console.log(value);
const { Search } = Input;

export const Team = ({ teamActions, signalRActions, userActions, teams, users, loading }) => {
    useEffect(() => {
        userActions.list()
        teamActions.list()

        return () => {
            signalRActions.leaveGroup(['user', 'role'].map((group) => ({ group })))
        };
    }, [])

    const columns = [
        {
            title: 'TEAM NAME',
            dataIndex: 'team_name',
            key: 'team_name',
        },
        {
            title: 'USERS',
            dataIndex: 'teamUsers',
            width: '70%',
            render: (text, record) => (
                <div style={{
                    display: 'flex', alignItems: 'flex-start',
                    // flexDirection: 'column' 
                }}>
                    {record.teamUsers.map(user => <Tag key={user.id} style={{ marginTop: 5 }}>{`${user.full_name}`}</Tag>)
                    }</div>
            ),
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            width: '10%',
            render: (text, record) => {
                return (

                    // (role) ? (<Tag color="orange">Read only</Tag>) :
                    //     (
                    <div style={{}}>
                        <Tooltip title={`Edit`}>
                            <EditOutlined style={{ color: '#1E6EFE', marginRight: "10px" }} 
                            type="link"
                            key={`edit-${record.id}`}
                            onClick={() => {
                                editRecord(record);
                            }} />

                        </Tooltip>

                        <Popconfirm
                            key={`popconfirm-${record.id}`}
                            title="Confirm delete?"
                            onConfirm={() =>
                                deleteRecord(record)
                            }
                        >
                            <Tooltip title={`Delete`}>
                                <DeleteOutlined style={{ color: '#1E6EFE' }} type="link"
                                    key={`delete-${record.id}`}
                                />
                            </Tooltip>
                        </Popconfirm>
                    </div >
                    // )
                );
            },
        },
    ];

    const [visible, setVisible] = useState(false)
    const [mode, setMode] = useState('New')
    const [currentRecord, setCurrentRecord] = useState(null)
    const addRecord = () => {
        setCurrentRecord(null)
        setMode('New')
        setVisible(!visible)
    }
    const editRecord = (record) => {
        setCurrentRecord(record)
        setMode('Edit')
        setVisible(!visible)
    }
    const openModal = () => {
        setVisible(!visible)
    }



    const handleSave = (data) => {
        (mode == 'New') ? teamActions.create(data) : teamActions.update(data);
        setVisible(false)
    }

    const deleteRecord = (data) => {
        teamActions.remove({ teamId: data.id })
    }

    const getTeams = () => {
        return (
            teams &&
            teams.map((team) => {
                let userIds = team.Team_Users.map(ele => ele.user_id);
                let teamUsers = (users.filter(user => userIds.includes(user.id)))
                return {
                    id: team.id,
                    team_name: team.team_name,
                    userIds,
                    teamUsers,
                };
            })
        );
    };

    return (
        <div>
            <Layout>
                <HeaderTop />
            </Layout>
            <Layout>
                <Sidebar />
                <div style={{ width: '100%' }} className="site-layout">
                    <Layout
                        className="site-layout-background"
                        style={{
                            margin: '24px 16px 0px 16px',
                            background: 'none'
                        }}
                    >
                        <Row style={{
                            width: '98%',
                            paddingLeft: '2%',
                            paddingRight: '0px'
                        }}>
                            <Col span={12} >
                                <h2 className="heading-small" >TEAMS</h2>
                            </Col>
                            <Col span={12} >
                                <Button className=" right-align-button"
                                    icon={<AlertOutlined />}
                                    type="primary"
                                    onClick={addRecord}
                                >
                                    ADD TEAM
                                </Button>
                            </Col>
                            <AddTeamForm onCreate={(data) => { handleSave(data) }} type={mode} users={users} record={currentRecord} visible={visible} onCancel={() => { setVisible(false); }} />
                        </Row>
                    </Layout>
                    <StandardTable loading={loading}
                        columns={columns}
                        dataSource={getTeams()} />
                </div>
            </Layout>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        teams: state.teamReducer.list,
        users: state.userReducer.list,
        loading: state.teamReducer.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators(UserActions, dispatch),
        teamActions: bindActionCreators(TeamActions, dispatch),
        signalRActions: bindActionCreators(SignalRActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
