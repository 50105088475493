import { notification } from "antd";
import {
  list as listTeams,
  create as createTeam,
  update as updateTeam,
  remove as removeTeam,
} from "../services/apiInteraction";

const namespace = "teams";

export const list = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_TEAM", payload: true });
      let data = await listTeams({ module: namespace });
      dispatch({ type: "LIST_TEAM", payload: data });
    } catch (err) {
      dispatch({ type: "SET_LOADING_TEAM", payload: false });
      notification.error({ message: err.message });
    }
  };
};

export const create = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_TEAM", payload: true });
      await createTeam({ module: namespace, data });
      dispatch({ type: "SET_LOADING_TEAM", payload: false });
      notification.success({ message: "Team Creation Successful" });
    } catch (err) {
      dispatch({ type: "SET_LOADING_TEAM", payload: false });
      notification.error({ message: err.message });
    }
  };
};

export const update = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_TEAM", payload: true });
      await updateTeam({ module: namespace, data })
      notification.success({ message: 'Team Updated Successful' })
      dispatch({ type: "SET_LOADING_TEAM", payload: false });
    }
    catch (err) {
      dispatch({ type: "SET_LOADING_TEAM", payload: false });
      notification.error({ message: err.message })
    }
  };
};

export const remove = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "SET_LOADING_TEAM", payload: true });
      await removeTeam({ module: namespace, data });
      notification.success({ message: "Team Removed Successful" });
      dispatch({ type: "SET_LOADING_TEAM", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING_TEAM", payload: false });
      notification.error({ message: err.message });
    }
  };
};
