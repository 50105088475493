import { notification } from 'antd';
import { list as listActivityChat, create as createActivityChat } from '../services/apiInteraction'

const namespace = 'activitychat'
export const list = (conditions) => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: "SET_LOADING_ACTIVITYCHAT", payload: true });
            let data = await listActivityChat({ module: namespace, conditions })
            dispatch({
                type: "LIST_ACTIVITYCHAT", payload: {
                    ...getState()[`activitychatReducer`].list, [conditions.alertActivityId]: data
                }
            });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ACTIVITYCHAT", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const create = (data, conditions) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ACTIVITYCHAT", payload: true });
            await createActivityChat({ module: namespace, data, stringifyJson: false, conditions })
            dispatch({ type: "SET_LOADING_ACTIVITYCHAT", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ACTIVITYCHAT", payload: false });
            notification.error({ message: err.message })
        }
    };
};