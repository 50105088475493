import React from "react";
import { connect } from "react-redux";
import "./login.css";
import { Row, Typography, Col } from "antd";

// import images
import whiteLogo from "../../assets/images/200-40.png";

import ForgetPasswordComponent from "../../auth/forgetpasswordComponent";

const { Title } = Typography;

const ForgetPassword = (props) => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Row className="loginPage">
        <Col className="login-left" xs={24} sm={24} md={8}>
          <a href="/login"><img
            style={{ marginLeft: "20px", marginTop: "20px" }}
            src={whiteLogo}
          /></a>
          <div className="position-absolute">
            <p
              className="font-24"
              style={{ marginLeft: "20px", color: "white" }}
            >
              Eliminate downtime and improve productivity with Procheck's PDM{" "}
            </p>
            <Title
              style={{ color: "white", fontWeight: 500, marginLeft: "20px" }}
              className=" bcolor"
              level={1}
            >
              Leading the digital transformation of manufacturing plants.
            </Title>
          </div>
        </Col>
        <Col xs={24} sm={24} md={16}>
          <ForgetPasswordComponent />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
