import { notification } from 'antd';
import { list as listAlerts, get as getAlert, create as createAlert, update as updateAlert, remove as removeAlert } from '../services/apiInteraction'

const namespace = 'alerts'
export const list = (conditions) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ALERT", payload: true });
            let data = await listAlerts({ module: namespace, conditions })
            dispatch({ type: "LIST_ALERT", payload: { list: data, ...conditions } });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ALERT", payload: true });
            notification.error({ message: err.message })
        }
    };
};

export const get = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ALERT", payload: true });
            let data = await getAlert({ module: namespace })
            dispatch({ type: "GET_ALERT", payload: data });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ALERT", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const create = (data, conditions) => {
    return async (dispatch) => {
        try {

            dispatch({ type: "SET_LOADING_ALERT", payload: true });
            await createAlert({ module: namespace, data })
            notification.success({ message: 'Alert Creation Successful' })
            dispatch({ type: "SET_LOADING_ALERT", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ALERT", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const update = (data, conditions) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ALERT", payload: true });
            await updateAlert({ module: namespace, data })
            notification.success({ message: 'Alert Updated Successful' })
            dispatch({ type: "SET_LOADING_ALERT", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ALERT", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const remove = (data, conditions) => {
    return async (dispatch) => {
        try {

            dispatch({ type: "SET_LOADING_ALERT", payload: true });
            await removeAlert({ module: namespace, data })
            notification.success({ message: 'Alert removed Successful' })
            dispatch({ type: "SET_LOADING_ALERT", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ALERT", payload: false });
            notification.error({ message: err.message })
        }
    };
};