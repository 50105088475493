import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Input, Form, Button, Select } from "antd";

import { FileAddOutlined } from "@ant-design/icons";
import "./addactivity.css";
import { bindActionCreators } from "redux";
import * as AlertActivityActions from "../../actions/alertactivityActions";

const keyMapping = {
  alertUrgency: 'alert_urgency',
  findings: 'findings',
  recommendedAction: 'recommended_action'
}
export const Addactivity = ({ visibleModal, onCancel, onOk, activity, alertActivityActions, teams }) => {
  const [form] = Form.useForm();
  const setFormField = (field, value) => {
    form.setFieldsValue({
      [field]: value
    })
  }
  const setBaseInfo = () => {
    console.log(activity)
    let obj = {};
    Object.keys(form.getFieldsValue()).forEach(key => {
      obj[key] = activity[keyMapping[key]] || '';
      if (key == 'teamId')
        obj['teamId'] = activity['team_id'] || '';
    });
    form.setFieldsValue(obj);
  }

  useEffect(() => {
    setBaseInfo()
  }, [visibleModal])
  const { TextArea } = Input;
  return (
    <div>
      <Modal
        title="Add Activity"
        visible={visibleModal}
        onOk={onOk}
        onCancel={onCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          className="modal-custom"
          name="form_in_modal"
          onFinish={async () => {
            try {
              let values = await form.validateFields()
              alertActivityActions.update({
                alertActivityId: activity?.id,
                ...values
              })
              onCancel()
            }
            catch (info) {
              console.log('Validate Failed:', info);
            }
          }}
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item rules={[
            {
              required: true,
              message: 'Please Mark Severity!',
            },
          ]} name="alertUrgency" label="Severity">
            <Select placeholder="Mark Severity"
              style={{
                width: '100%',
                border: '1px solid #E6E7E9'
              }}
              onChange={(value) => setFormField('alertUrgency', value)}
              bordered={true}
            >
              <Select.Option value={'Unknown'}>Unknown</Select.Option>
              <Select.Option value={'Crtical'}>Critical</Select.Option>
              <Select.Option value={'Faulty'}>Faulty</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="teamId" label="Assign Team">
            <Select placeholder="Select Team"
              style={{
                width: '100%',
                border: '1px solid #E6E7E9'
              }}
              onChange={(value) => setFormField('teamId', value)}
              bordered={true}
              // mode="multiple"
              allowClear={true}                    >
              {
                teams && teams.map(team => (
                  <Select.Option value={team.id}>{team.team_name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          <Form.Item
            name="findings"
            label="Findings"
          >
            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
          </Form.Item>

          <Form.Item
            name="recommendedAction"
            label="Comments"
          >
            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
          </Form.Item>

          <Form.Item name="submit">
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                fontWeight: "500",
                fontSize: "80%",
                marginBottom: "0px",
              }}
            >
              Post
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertActivityActions: bindActionCreators(AlertActivityActions, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addactivity);
