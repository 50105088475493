const initialState = {
    list: [],
    loading: false,
    subscribed: false
}

const machineReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LIST_MACHINE":
            return {
                ...state,
                list: action.payload,
                loading: false,
                subscribed: true
            };
        case "SET_LOADING_MACHINE":
            return {
                ...state,
                loading: action.payload,
            };
        case "RESET_LIST_MACHINE":
            return {
                ...state,
                list: [],
                subscribed: false
            };
        default:
            return state;
    }
};

export default machineReducer;