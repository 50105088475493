import React from "react";
import { connect } from "react-redux";
import "./table.css";
import { Layout, Input, Row, Col, Button, Card, Table } from "antd";

import {
  AppstoreAddOutlined,
  UserAddOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

// import images
const onSearch = (value) => console.log(value);
const { Search } = Input;

export const table = ({ dataSource, loading, columns }) => {
  return (
    <div>
      <Layout>
        <div style={{ width: "100%" }} className="site-layout">
          <Layout
            className="site-layout-background"
            style={{
              margin: "0px 16px 0px 16px",
              background: "none",
            }}
          ></Layout>
          <Layout>
            <Row>
              <Card
                style={{
                  width: "95%",
                  margin: "auto",
                }}
              >
                <Table
                  loading={loading}
                  scroll={{ x: 400 }}
                  dataSource={dataSource}
                  columns={columns}
                />
              </Card>
            </Row>
          </Layout>
        </div>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default table;
