import { notification } from 'antd';
import { list as listAlertActivities, update as updateAlertActivities } from '../services/apiInteraction'

const namespace = 'alertactivities'
export const list = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ALERTACTIVITY", payload: true });
            let data = await listAlertActivities({ module: namespace })
            dispatch({ type: "LIST_ALERTACTIVITY", payload: data });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ALERTACTIVITY", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const update = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_ALERTACTIVITY", payload: true });
            await updateAlertActivities({ module: namespace, data })
            notification.success({ message: 'Activity Recorded Successful' })
            dispatch({ type: "SET_LOADING_ALERTACTIVITY", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_ALERTACTIVITY", payload: false });
            notification.error({ message: err.message })
        }
    };
};