const initialState = {
    list: [],
    current: {},
    loading: false,
    subscribed: false
}

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ROLE":
            return {
                ...state,
                current: action.payload,
                loading: false
            };
        case "LIST_ROLE":
            return {
                ...state,
                list: action.payload,
                loading: false,
                subscribed: true
            };
        case "SET_LOADING_ROLE":
            return {
                ...state,
                loading: action.payload,
            };
        case "RESET_LIST_ROLE":
            return {
                ...state,
                list: [],
                subscribed: false
            };
        default:
            return state;
    }
};

export default roleReducer;