
import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Col, Row } from "antd";
import { FaThermometerEmpty } from 'react-icons/fa';
import { GiVibratingBall } from 'react-icons/gi';




import { ThunderboltOutlined } from "@ant-design/icons";

// CSS
import "./machineInfo.css";

export const MachineInfoCard = (props) => {
const history = useHistory();
const [globalMachineId, setGlobalMachineId] = useState('')

function getMachineId () {
  let a = props.machineid;
  setGlobalMachineId(a);
  console.log(globalMachineId)
  history.push('/machines')
  localStorage.setItem("machineId", a);
}

let colorIcon=  (props.border== "Critical")? "#ca2b30" : (props.border == "Faulty")? "#ffaa00" :"#788090" ;
let colorText=  (props.border== "Critical")? "#ca2b30" : (props.border == "Faulty")? "#ffaa00" :"#404040" ;
let colorCounter=  (props.border== "Critical")? "#ca2b30" : (props.border == "Faulty")? "#ffaa00" :"#14212b" ;
let colorBorder=  (props.border== "Critical")? "#ca2b30" : (props.border == "Faulty")? "#ffaa00" :"#caced4" ;

return (
    <div>
      <Col
        style={{ marginTop: "10px"}}
        className="machine-info-card"
        sm={24}
        xs={24}
        >
        <Card
          title={<h4 className="font-weight-bold" >{props.machineName}</h4>}
          onClick={getMachineId}
          title={<Row style={{position:'relative'}}><h4 className="font-weight-bold ">{props.machineName}</h4>
           {(props.border != "Normal")? <p className="exclaimationSign" style={{textAlign:'right' ,color:colorBorder}}>!</p> : <p className="exclaimationSign"></p>}
          </Row>}
          style={{
            cursor:'pointer',
            width: "100%",
            borderColor:colorBorder  
          }}
          className="m-auto onHover"
        >
          <Row>
            <div className="w-50 p-0 m-0 border-right border-bottom padding-bottom">
              <Row className="p-0 m-0">
                <Col className="p-0 m-0" span={5}>
                  <ThunderboltOutlined style={{color:colorIcon }} className="machine-info-card-icon" />
                </Col>
                <Col className="p-0 m-0" span={19}>
                  <p  style={{color:colorCounter }}  className="machine-info-card-counter">{props.status}</p>
                  <p style={{color:colorText }}  className="machine-info-card-content">Status</p>
                </Col>
              </Row>
            </div>
            <div className="w-50 p-0 m-0  border-bottom">
              <Row className="p-0 m-0 ">
                <Col className="p-0 m-0" span={5}>
                  <GiVibratingBall  style={{color:colorIcon }} className="machine-info-card-icon" />
                </Col>
                <Col className="p-0 m-0" span={19}>
                  <p  style={{color:colorCounter,fontSize:'12px' }}  className="machine-info-card-counter">X:{props.vibration_x} - Y:{props.vibration_y} - Z:{props.vibration_z}</p>
                  <p  style={{color:colorText }}  className="machine-info-card-content">Vibrations</p>
                </Col>
              </Row>
            </div>
          </Row>

          <Row>
            <div className="w-50 p-0 m-0 border-right mt-2">
              <Row className="p-0 m-0  mt-1">
                <Col className="p-0 m-0" span={5}>
                  <FaThermometerEmpty  style={{color: colorIcon}} className="machine-info-card-icon"  />
                </Col>
                <Col className="p-0 m-0" span={19}>
                  <p  style={{color:colorCounter }}  className="machine-info-card-counter">
                    {props.temperature}
                  </p>
                  <p  style={{color:colorText }}  className="machine-info-card-content">Temperature</p>
                </Col>
              </Row>
            </div>
            {/* if health score in future remove styling from div */}
            <div
              className="w-50 p-0 m-0"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Row className="p-0 m-0 mt-2">
                {/* <Col className="p-0 m-0" span={5}>
                  <HeartOutlined className="machine-info-card-icon" />
                </Col>
                <Col className="p-0 m-0 mt-1" span={19}>
                  <p className="machine-info-card-counter">
                    {props.healthScore}
                  </p>
                  <p className="machine-info-card-content">Health Score</p>
                </Col> */}
                <p>{`N / A`}</p>
              </Row>
            </div>
          </Row>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(MachineInfoCard)
export default MachineInfoCard;
