import { notification } from 'antd';
import machines from '../pages/machines/machines';
import { list as listMachineKpis } from '../services/apiInteraction'
import { fetchSensorData as fetchDataForSensor } from '../services/apiInteraction'

const namespace = 'machinekpis'
export const list = (conditions) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_MACHINEKPIS", payload: true });
            let data = await listMachineKpis({ module: namespace, conditions })
            dispatch({ type: "LIST_MACHINEKPIS", payload: data });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_MACHINEKPIS", payload: false });
            notification.error({ message: err.message })
        }
    };
};
export const fetch = (machineId)=>{
    return async (dispatch) => {
        try{
            dispatch({ type: "SET_LOADING_MACHINEKPIS", payload: true });
            fetchDataForSensor({module: namespace,machineId:machineId});
            dispatch({ type: "SET_LOADING_MACHINEKPIS", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_MACHINEKPIS", payload: false });
            notification.error({ message: err.message })
        }
    };
}