import { leaveSignalRGroup } from '../services/apiInteraction'
import moduleCustomMapping from './moduleCustomMapping'

export const leaveGroup = (groups, sendKeyToReducer = false) => {
    return (dispatch, getState) => {
        let groupIds = groups.reduce((totalGroups, groupRecord) => {
            const { group, key = 'id', type = 'list', standalone = false } = groupRecord
            const modules = moduleCustomMapping[group] || [group]
            return totalGroups.concat(
                modules.reduce((totalModules, module) => {
                    if (!getState()[`${module}Reducer`]?.subscribed)
                        return totalModules
                    let list = getState()[`${module}Reducer`].list
                    let groupIds = standalone ? [`${group}-${key}`] :
                        (type == 'dict' ? Object.keys(list) : list).map(record => `${group}-${type == 'dict' ? record : record[key]}`);
                    dispatch({ type: `RESET_LIST_${module.toUpperCase()}`, payload: sendKeyToReducer ? key : undefined });
                    if (!standalone)
                        groupIds.unshift(group)
                    return totalModules.concat(groupIds)
                }, []))
        }, [])
        if (groupIds.length > 0)
            leaveSignalRGroup(groupIds)
    }
};