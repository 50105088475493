const initialState = {
  loggedIn: null,
  isSuperAdmin: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGGED_IN":
      return {
        ...state,
        loggedIn: action.payload,
      };
    case "SET_SUPER_ADMIN":
      return {
        ...state,
        isSuperAdmin: action.payload,
      };
    case "LOGIN_USER":
      return {
        ...state,
        loggedIn: true,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default authReducer;
