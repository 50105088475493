const initialState = {
  list: [],
  loading: false,
  subscribed: false,
};

const machinekpisReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LIST_MACHINEKPIS":
      return {
        ...state,
        list: action.payload,
        loading: false,
        subscribed: true,
      };
    case "SET_LOADING_MACHINEKPIS":
      return {
        ...state,
        loading: action.payload,
      };
    case "RESET_LIST_MACHINEKPIS":
      return {
        ...state,
        list: [],
        subscribed: false,
      };
    default:
      return state;
  }
};

export default machinekpisReducer;
