const initialState = {
  list: [],
  current: {},
  loading: false,
  subscribed: false,
};

const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TEAM":
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case "LIST_TEAM":
      return {
        ...state,
        list: action.payload,
        loading: false,
        subscribed: true,
      };
    case "SET_LOADING_TEAM":
      return {
        ...state,
        loading: action.payload,
      };
    case "RESET_LIST_TEAM":
      return {
        ...state,
        list: [],
        subscribed: false,
      };
    default:
      return state;
  }
};

export default teamReducer;
