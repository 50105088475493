import chartReducer from "./chartReducer";
import sideBarReducer from './sideBarReducer';
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import roleReducer from "./roleReducer";
import machineReducer from "./machineReducer";
import machinekpisReducer from "./machinekpisReducer"
import alertactivityReducer from "./alertactivityReducer";
import sensorValueReducer from "./sensorValueReducer";
import activitychatReducer from "./activitychatReducer"
import alertReducer from "./alertReducer";
import teamReducer from "./teamReducer";
import healthReducer from "./healthReducer"
import { combineReducers } from "redux";

const appReducer = combineReducers({
    chartReducer,
    sideBarReducer,
    authReducer,
    userReducer,
    roleReducer,
    machineReducer,
    alertactivityReducer,
    machinekpisReducer,
    sensorValueReducer,
    activitychatReducer,
    alertReducer,
    teamReducer,
    healthReducer
});
const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        const { routing } = state
        state = { routing }
    }
    return appReducer(state, action)
}

export default rootReducer;