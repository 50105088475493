import React from "react";
import { connect } from "react-redux";
import "./tempChartCard.css";
import { Card, Button, Radio } from "antd";
import { TableOutlined } from "@ant-design/icons";
import TemperatureCard from "../Chart/tempChart";

export const TempChartCard = ({title, dataSource}) => {
  return (
    <div className="charttable-card-head">
      <Card
        className="cardTable-header"
        title={`${title}`}
        style={{ width: "100%" }}
      >
        <div className="cardTable-header-button">
          {/*<Radio.Group>
            <Radio.Button className="radio-button-custom" value="large">
              Day
            </Radio.Button>
            <Radio.Button className="radio-button-custom" value="default">
              Week
            </Radio.Button>
            <Radio.Button className="radio-button-custom" value="small">
              Month
            </Radio.Button>
          </Radio.Group>*/}
        </div>
        <h3
          style={{
            textAlign: "right",
            marginRight: "20px",
            marginTop: "20px",
          }}
        >
          Current Value: {dataSource.length > 0 ? Math.round(dataSource[dataSource.length-1].temperature) : 'N/A'}&#176;C
        </h3>
        <TemperatureCard data={dataSource} />
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TempChartCard);
