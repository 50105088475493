import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./rightsidebar.css";
import { Layout, Row, Col, Card, Button, Input, Collapse, Image } from "antd";
import moment from "moment";
//component
import ActivityLog from "../ActivityLog/activityLog";
import AddactivityModal from "../addactivity/addactivity";

import {
  PlusOutlined,
  MessageOutlined,
  FileAddOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";

//   Searchbar contant
const { Search } = Input;
// toggle Div
const { Panel } = Collapse;
function callback(key) {
  console.log(key);
}


export const RightSidebar = ({ alertActivities, teams }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [filteredAlertActivities, setFilteredAlertActivities] = useState([]);
  const [searchString, setSearchString] = useState("");
  const searchActivityLogs = (string) => {
    if (string) {
      let filtered = alertActivities.filter(
        (activity) =>
          activity?.findings?.toLowerCase()?.includes(string.toLowerCase()) ||
          activity?.recommended_action?.toLowerCase()?.includes(string.toLowerCase()) ||
          activity?.machine_alert?.alert?.alert_type?.toLowerCase()?.includes(string.toLowerCase()) ||
          activity.alert_urgency?.toLowerCase()?.includes(string.toLowerCase())
          );
      setFilteredAlertActivities(filtered);
    } else setFilteredAlertActivities([]);
    setSearchString(string);
  };
  useEffect(() => {
    searchActivityLogs(searchString);
  }, [alertActivities]);
  
  return (
    <div className="w-100">
      
      <div className="right-sidebar1">
        {/*
        <Card
          title="Activity Log"
        // extra={
        //   <a className="add-icon" onClick={() => setIsVisible(true)}>
        //     <PlusOutlined /> ADD ACTIVITY
        //   </a>
        // }
        >
          
              </Card>
              
                <Card style={{ textAlign: 'center' }}
                  title="Prdeicted Health Status"
              // extra={
              //   <a className="add-icon" onClick={() => setIsVisible(true)}>
              //     <PlusOutlined /> ADD ACTIVITY
              //   </a>
              // }
              >
                 
                  <Row>
                      <label style={{ fontSize: 18, border: '1px solid black', padding: '5px', width: '100%', height:'80%', textAlign: 'center', boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.5)' }} className="centre-align-icon"> Model Accuracy= 0.83281</label>
                  </Row>
                  <Row>
                      <label style={{ fontSize: 18, border: '1px solid black', padding: '5px', width: '100%', height: '80%', textAlign: 'center', boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.5)' }} className="centre-align-icon">
                      <h1>X vibration prediction: Healthy</h1>
                      <h1>Y vibration prediction: Healthy</h1>
                      <h1>Z vibration prediction: Healthy</h1>

                      </label>
                  </Row>
                  
        </Card>
        */}




        {/* {console.log(teams.filter(ele => ele.id == alertActivities?.team_id))} 
        {console.log(alertActivities)} */}
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default RightSidebar;
