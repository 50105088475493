import React from "react";
import { Result, Button, Typography } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import coloredLogo from "../../assets/images/logo-black.png";

export const Error404 = (props) => {
  return (
    <div>
      <Result
        icon={<img
          src={coloredLogo}
        />}
        title={"Error 404 Page Not Found"}
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link style={{ color: "white" }} to="/">
            <Button type="primary">
              Back
          </Button>
          </Link>
        }
      />
      ,
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Error404);
