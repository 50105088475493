import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import "./auth.css";
import {
  Button,
  Row,
  Typography,
  Form,
  Input,
  Space,
  Modal,
  Card,
  Divider,
  notification,
  Spin,
  InputNumber,
} from "antd";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import config from './forgetPassword';

import Amplify, { Auth } from 'aws-amplify';
import { awsconfig } from '../config'



import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser,
  } from "amazon-cognito-identity-js";

// import images
import whiteLogo from "../assets/images/200-40.png";

const forgetpasswordNotification = () => {
  notification.success({
    message: 'Email Send',
    description:
      'Varification Code send to Youy email.',
    // onClick: () => {
    //   console.log('Notification Clicked!');
    // },
  });
};


const { Title } = Typography;

Amplify.configure({
    Auth: awsconfig
});

const ForgetPasswordComponent = (props) => {

  const[verifyCode, setVerifyCode] = useState('')
  
  const[newPassword, setNewPassword] = useState('')

  const [msg, setMsg] = useState('')

  const onFinish = async () => {
    Auth.forgotPassword(email)
    .then(
      data => console.log(data)
    )
    .catch(err => console.log(err));
  };

  const sendVarificationCode = async () => {
    Auth.forgotPasswordSubmit(email, verifyCode, newPassword)
    .then(
      data => console.log(data)
    )
    .catch(err => console.log(err));

    setMsg('Password change successfully')
    setTimeout(()=>{history.push("/")},2000);
    
  };

  const sendVarificationCodeFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  const [isModalVisible, setIsModalVisible] = useState(false);

  const [buttonDissable, setbuttonDissable] = useState(false);

  const [emialError, setEmialError] = useState("");

  const [emailtxt, setEmailtxt] = useState("");
  const [email, setEmail] = useState("")

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  

  function emailValidation(email) {
    let title = email;
    setEmailtxt(title);
    var mailformat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // var mailformat = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(procheck)\.com$/g;
    if (title.match(mailformat)) {
      setEmialError("");
    } else {
      setEmialError("Invalid Email and password");
    }
  }

  const handleSubmitBtn = ()=>{
    forgetpasswordNotification()
    showModal()
  }
  useEffect(() => {
    emailValidation(email)
    setVerifyCode(verifyCode)
  }, [email])
let history = useHistory();

  return (
    <div>
      <Row style={{ justifyContent: "center" }} className="loginPage">
        <Card style={{ minHeight: "100vh", paddingTop: "20vh" }}>
          <Row style={{ justifyContent: "center" }}>
            <Title
              style={{ color: "#1e6efe", fontWeight: 500 }}
              className="text-center bcolor"
              level={2}
            >
              Forget Password
            </Title>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Form
            {...formItemLayout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <label style={{ color: "#B0B4C0" }}>
                <p>Enter Your Email</p>
                <Form.Item>
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{ width: "300px" }}
                  />
                  <p>{emialError}</p>
                </Form.Item>
              </label>
              <Divider />

              <div className="remember-me">
                <Form.Item {...tailLayout}>
                  <Button
                    style={{ width: "300px", height: "30px" }}
                    type="primary"
                    htmlType="submit"
                    onClick={handleSubmitBtn}
                    disabled={emialError == "" && emailtxt != "" ? false : true}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Row>
        </Card>
      </Row>

      {/* Forget Password Modal   */}
      <Modal
        title="Forget Password"
        footer= {null}
        closable= {true}
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
        layout="vertical"
         onFinish={sendVarificationCode}
         onFinishFailed={sendVarificationCodeFailed}
          style={{marginTop:5}}
        >
          <Form.Item
          label="Enter Your Code"
          rules={[
            {
              required: true,
              min:6,
              max:6,
              message:'Code must be 6 digit'
            }
          ]}
          name="code"
          >
          <Input
            onChange={(e) => setVerifyCode(e.target.value)}
            placeholder={`Enter 6 digit code`}
            value={verifyCode}
            rules={[{ required: true, message: "Required" }]}
            style={{ width: "100%" }}
            type="number"
            // min={6}
            // max={10}
            defaultValue={0}
          />
        </Form.Item>
        {/* <label>Enter New Password</label> */}
        <Form.Item
          style={{marginTop:20}}
          label="Enter New Password"
          rules={[
            {
              required: true,
              message:
                "Password must have a min length of 8 characters including 1 special character, 1 uppercase and 1 digit",
              pattern:
                /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            },
          ]}
          name="password"
          // label="Enter new password"
            // <Space>
            //   <label>{"New Password"}</label>
            //   {
            //     <Typography.Text type="secondary">{` (Optional)`}</Typography.Text>
            //   }
            // </Space>
          
        >
          <Input.Password
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword}
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>


        <Form.Item style={{justifyContent:'center'}}>
          <p style={{textAlign:'center'}}><Button 
            style={{ width: "250px" , justifyContent:'center'}}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
          </p>
        </Form.Item>
          <p style={{color:'#1890ff', marginTop:20, textAlign:'center'}}>{msg}</p>



        </Form>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPasswordComponent);
