import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import "./machineSettings.css";

import { Layout, Input, Row, Upload, Form, Button, Card, Select, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";

// import component
import HeaderTop from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";
import { bindActionCreators } from "redux";
import * as MachineActions from "../../actions/machineActions";
import * as AuthActions from "../../actions/authActions";
import { Label } from "recharts";

const { Search } = Input;
const { Option } = Select;

const keyMapping = {
  machineName: 'machine_name',
  oemName: 'oem_name',
  modelNumber: 'model_number',
  sensorNodeId:'sensor_node_id',
  dataEntryEndpoint:'data_entry_endpoint',
  syncMachineId:'sync_machine_id',
  id:'id',
}
const uploadButton = (
  <div>
    { <PlusOutlined />}
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
export const MachineSettings = ({ machines, machineActions, loading,isSuperAdmin }) => {
  const [machine, setMachine] = useState({});
  const [pageLoaded, setPageLoaded] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);
  const setBaseInfo = () => {
    let obj = {};
    Object.keys(form.getFieldsValue()).forEach(key => {
      obj[key] = machine[keyMapping[key]] || '';
    });
    form.setFieldsValue(obj);
  }

  useEffect(() => {
    setBaseInfo()
  }, [machine])
  const setMachineAndFormula = (machineId) => {
    setMachine(machines.find((machine) => machine.id == machineId));
  };

  useEffect(() => {
    if (Object.keys(machine).length === 0 && machines.length > 0) {
      let machineId = machines[0]?.id
      if (!pageLoaded) {
        let machineFound = machines.find(machine => machine.id == localStorage.getItem('machineId'))
        if (machineFound)
          machineId = machineFound.id
      }
      setMachineAndFormula(machineId);
      if (!pageLoaded)
        setPageLoaded(true)
    }

  }, [machines]);

  const [form] = Form.useForm();

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const updateMachine = async () => {
    try {
      let values = await form.validateFields()

      const formData = new FormData();
      let body = {
        machineId: machine?.id,
        machine_name: values.machineName,
        oem_name: values.oemName,
        model_number: values.modelNumber,
        
      }
      if(values.sensorNodeId)
        body.sensor_node_id= values.sensorNodeId;
      if(values.syncMachineId)
        body.sync_machine_id = values.syncMachineId;
console.log(body);
      if (Array.isArray(values.dragger) && values.dragger.length > 0)
       { 
         formData.append('file', values?.dragger[0]?.originFileObj);   
       }

      Object.keys(body).forEach(key => formData.append(key, body[key]))
      machineActions.update(formData, {
        filename:
          `machine_${machine?.id}_${(new Date().getTime())}_${values?.dragger[0]?.originFileObj?.name}`
      })
    }
    catch (info) {
      console.log('Validate Failed:', info);
    }
  }


  return (
    <div>
      <Layout>
        <HeaderTop />
      </Layout>
      <Layout>
        <Sidebar />
        <div
          style={{ width: "100%" }}
          className="site-layout responsive-margin-60"
        >
          <Layout
            className="site-layout-background"
            style={{
              margin: "24px 16px 0px 16px",
              background: "none",
            }}
          ></Layout>
          <Spin spinning={loading}>
            <Row style={{
              width: '100%',
              paddingLeft: '0%',
              paddingRight: '0px',
              display: 'flex'
            }}>
              <h2 style={{marginLeft:'18px'}}  className="heading-small" >{`MACHINE INFORMATION`}</h2>
              <Card
                style={{
                  width: 'calc(100% - 100px)',
                  margin: '0px 10px 10px 15px'
                }}
              >
                <Row style={{
                  margin: '10px',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <Select
                    placeholder="Select Machine"
                    style={{
                      float: "right",
                      background: "white",
                      width: 238,
                      border: "1px solid #E6E7E9",
                    }}
                    value={machine.id}
                    onChange={(value) => setMachineAndFormula(value)}
                    bordered={true}
                  >
                    {machines.map((machine) => (
                      <Select.Option key={machine.id}>
                        <a>{machine.machine_name}</a>
                      </Select.Option>
                    ))}
                  </Select>
                </Row>
                <Row>
                  <Form
                    style={{
                      paddingLeft: "20px",
                      paddingTop: "20px",
                      width: "99%",
                    }}
                    form={form}
                    onFinish={updateMachine}
                    layout="vertical"
                    className="modal-custom"
                    name="form_in_modal"
                    initialValues={{
                      modifier: "public",
                    }}
                  >
                    <Form.Item
                      rules={[
                        {
                          min: 2,
                          message: "Machine name must be minimum 2 characters.",
                        },
                        { max: 20, message: "String limit exceeded" },
                        { required: true, message: "*Required" },
                      ]}
                      name="machineName"
                      label="Machine Name"
                    >
                      <Input
                        style={{ width: "100%" }}
                        className="modal-input"
                      />
                    </Form.Item>

                    <Form.Item
                      rules={[
                        { min: 2, message: "OME must be minimum 2 characters." },
                        { max: 20, message: "String limit exceeded" },
                      ]}
                      name="oemName"
                      label="OEM Name"
                    >
                      <Input className="modal-input" />
                    </Form.Item>

                    <Form.Item
                      rules={[
                        {
                          min: 2,
                          message: "Model number must be minimum 2 characters.",
                        },
                        { max: 20, message: "String limit exceeded" },
                      ]}
                      name="modelNumber"
                      label="Model Number"
                    >
                      <Input
                        className="modal-input"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    {(isSuperAdmin) ? (
                              <Form.Item
                              rules={[
                                {
                                  min: 5,
                                  message: "Sensor Id must be minimum 5 characters.",
                                },
                                { max: 20, message: "String limit exceeded" },
                              ]}
                              name="sensorNodeId"
                              label="Sensor Node Id"
                            >
                              <Input
                                className="modal-input"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                    ):''}
                    {(isSuperAdmin) ? (
                              <Form.Item
                              rules={[
                                {
                                  min: 10,
                                  message: "Machine Id must be minimum 10 characters.",
                                },
                                
                              ]}
                              name="syncMachineId"
                              label="Sync Machine Id"
                            >
                              <Input
                                className="modal-input"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                    ):''}
                    {(isSuperAdmin) ? (
                              <Form.Item
                              rules={[
                                {
                                  min: 10,
                                  message: "Machine Id must be minimum 10 characters.",
                                },
                                
                              ]}
                              name="id"
                              label="Machine Id"
                            >
                              <Input
                                className="modal-input"
                                disabled="true"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                    ):''}
                    <Form.Item

                      name="dataEntryEndpoint"
                      label="Data Entry Endpoint"
                    >
                      <Input
                        className="modal-input"
                        disabled="true"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <Form.Item label="Machine Image">
                      <Form.Item
                        name="dragger"
                        valuePropName="file"
                        getValueFromEvent={normFile}
                        noStyle
                      >
                        <Upload
                          accept="image/png, image/jpeg"
                          multiple={false}
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          name="files"
                          beforeUpload={() => false}
                          onChange={(file) => {
                            setImageUpload(URL.createObjectURL(file.file))
                          }}
                          style={{
                            width: "300px",
                          }}
                        >
                          {
                            imageUpload || machine?.image_url ?
                              <img src={imageUpload || machine?.image_url} alt="avatar" style={{ width: '100%' }} /> :
                              uploadButton}
                        </Upload>
                      </Form.Item>
                    </Form.Item>

                    <Form.Item name="submit">
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "150px",
                          fontWeight: "500",
                          fontSize: "80%",
                          marginBottom: "0px",
                          float: "right",
                        }}
                      >
                        Submit
                    </Button>
                    </Form.Item>
                  </Form>
                </Row>
              </Card>

            </Row>
          </Spin>
        </div>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    machines: state.machineReducer.list,
    loading: state.machineReducer.loading,
    isSuperAdmin: state.authReducer.isSuperAdmin
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    machineActions: bindActionCreators(MachineActions, dispatch),
    actions: bindActionCreators(AuthActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineSettings);
