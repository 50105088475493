import React, { useState, useEffect, Component } from "react";
import serv from "../../../services/Email.js";

import {
  Button,
  Row,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Space,
  Switch,
  Typography,
  Col,
  notification
} from "antd";
//CSS
import "./AddAlertForm.css";

const FormItem = Form.Item;
const children = []
const alertSettings = {
  to: null, from: null
}
const emails = {
  to: [],
  cc: [],
  bcc: []
}
const AddAlertForm = ({ type, visible, onCancel, onCreate, record, machines, current }) => {
    



  let status = false
  let machineIds = []

  const [form] = Form.useForm();
  const setFormField = (field, value) => {
    form.setFieldsValue({
      [field]: value
    })
  }
function PostData(e){
        //e.preventDefault();
    const sgMail = require('@sendgrid/mail')
    sgMail.setApiKey(process.env.SENDGRID_API_KEY)

    const msg = {
        to: 'aqib@procheck.pk', // Change to your recipient
        from: 'notification@procheck.pk', // Change to your verified sender
        subject: 'Sending with SendGrid is Fun',
        text: 'and easy to do anywhere, even with Node.js',
        html: '<strong>and easy to do anywhere, even with Node.js</strong>',
    }

    /*sgMail
        .send(msg)
        .then((response) => {
            console.log(response[0].statusCode)
            console.log(response[0].headers)
        })
        .catch((error) => {
            console.error(error)
        })
        console.log(e);
        */
    }
  const alertTypes = [
    "Temperature",
    "Vibration_X",
    "Vibration_Y",
    "Vibration_Z",
    "Kurtosis_X",
    "Kurtosis_Y",
    "Kurtosis_Z",
    "P2P_X",
    "P2P_Y",
    "P2P_Z",
    "Z2P_X",
    "Z2P_Y",
    "Z2P_Z",
    "FFT",
  ];
  let title = (type == 'Edit') ? 'EDIT ALERT' : 'ADD NEW ALERT'
  let btnText = (type == 'Edit') ? 'UPDATE' : 'ADD'
    const data = type === 'Edit' ? { ...record } : {};
    function returnUnit(type) {
        if (type == "Temperature") {
            return "\u00b0C"
        }
        else if (type == "Vibration_X" || type == "Vibration_Y" || type == "Vibration_Z" || type == "P2P_X" || type == "P2P_Y" || type == "P2P_Z" || type == "Z2P_X" || type == "Z2P_Y" || type == "Z2P_Z") {
            return "mm/s"
        }
        return "";
    }
  const setBaseInfo = () => {
    //on new form
    if (visible && type !== 'Edit') {
      setTypeAlert(null)
      form.resetFields();
      machineIds.push(current.id)
    }
    //on edit form
    else if (visible && type === 'Edit') {
      form.resetFields();
      machineIds.push(current.id)

      setFormField('content', data.content || false)
      setFormField('subject', data.subject || false)
      setFormField('alertEnabled', data.alert_enabled || false)
      status = data.alert_enabled || false

      setFormField('to', (data.emails.to.length >= 1) ? data.emails.to : [])
      emails.to = (data.emails.to.length >= 1) ? data.emails.to : []
      setFormField('cc', (data.emails.cc.length >= 1) ? data.emails.cc : [])
      emails.cc = (data.emails.cc.length >= 1) ? data.emails.cc : []
      setFormField('bcc', (data.emails.bcc.length >= 1) ? data.emails.bcc : [])
      emails.bcc = (data.emails.bcc.length >= 1) ? data.emails.bcc : []

      setFormField('alertSettingFrom', typeof(data.alert_settings.from) == 'number' ? data.alert_settings.from : null)
      alertSettings.from = typeof(data.alert_settings.from) == 'number' ? data.alert_settings.from : null
      setFormField('alertSettingTo', typeof(data.alert_settings.to) == 'number' ? data.alert_settings.to : null)
      alertSettings.to = typeof(data.alert_settings.to) == 'number' ? data.alert_settings.to : null

      setFormField('alertType', data.alert_type || null)
      setTypeAlert(data.alert_type)
    }
    setFormField('machineIds', current.id)
    console.log(form.getFieldsValue())
  }

  const [typeAlert, setTypeAlert] = useState(null);
  const handleSelectedValue = (value) => {
    setTypeAlert(value);
  };

  useEffect(() => {
    setBaseInfo()
  }, [visible])
   
  //----------------==emailValidations--------------------------------
  const toEmailChange = (x) => {
    let newElem = x[x.length - 1]
    if (emails.to > x.length || validateEmail(newElem)) {
      emails.to = x
    }
    setFormField('to', emails.to)
  }
  const ccEmailChange = (x) => {
    let newElem = x[x.length - 1]
    if (emails.cc.length > x.length || validateEmail(newElem)) {
      emails.cc = x
    }
    setFormField('cc', emails.cc)
  }
  const bccEmailChange = (x) => {
    let newElem = x[x.length - 1]
    if (emails.bcc.length > x.length || validateEmail(newElem)) {
      emails.bcc = x
    }
    setFormField('bcc', emails.bcc)
  }
  const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  //-----------------emailValidation Ends-------------------------------
  
  const handleData = (values) => {

  if((alertSettings.to == null|| alertSettings.to == undefined) && (alertSettings.from == null || alertSettings.from == undefined))
  {
    let message = 'Please enter Min or Max value for alert.'
    notification.error({message});
    return;
  }    
  if (typeof (values.machineIds) == "string"){
        values.machineIds = values.machineIds.split(' ')
      }
      values = (type == 'Edit') ? { ...values, emails, alertSettings, alertId: record.id } : { ...values, alertSettings, emails }
      let { alertSettingFrom, alertSettingTo, bcc, cc, to, submit, ...params } = values;
      onCreate(params);
      form.resetFields();
    
  }
  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        form={form}
        hideRequiredMark={false}
        layout="vertical"
        name="form_in_modal"
              onSubmit= {PostData(form)}
          
        onFinish={() => {
          form
            .validateFields()
            .then((values) => {
              handleData(values)
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        {/* ----------ALERT TYPE SELECTION--------------------- */}
        <FormItem className='hello1' label={`Type`} name="alertType">
          <Select
                      allowClear={true}
                      onChange={(value) => { setFormField('alertType', value); handleSelectedValue(value); 
 }}
                      placeholder="Select Alert Type"
                      style={{
                          width: '100%',
                          border: '1px solid #E6E7E9'
                      }}
                      bordered={true}
                      disabled={(type == 'Edit') ? true : false}
          >
            {Object.values(alertTypes).map(element => (
              <Select.Option key={element}>{element}</Select.Option>
            ))}
          </Select>
        </FormItem>

        {((typeAlert != undefined) && (typeAlert != " " || typeAlert != null)) && (


          <div style={{ width: "100%" }}>
            {/* machine and status end */}
            <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Col xs={24} sm={24} md={12}>
                <FormItem
                  rules={[
                    { required: true, message: "Select a machine" },
                  ]}
                  label='Machine' name='machineIds'
                >
                  <Select placeholder="Select a Machine"
                    style={{
                      width: '90%',
                      border: '1px solid #E6E7E9'
                    }}
                    onChange={(value) => { setFormField('machineIds', value); machineIds.push(value) }}
                    bordered={true}
                    allowClear={true}
                    disabled={true}
                  >
                    {
                      machines.map(machine => (
                        <Select.Option value={machine.id}>{machine.machine_name}</Select.Option>
                      ))
                    }
                  </Select>
                </FormItem>
              </Col>
              <Col s={24} sm={24} md={12} style={{ alignSelf: 'center', }}>
                <Row style={{ justifyContent: 'space-around', alignItems: 'baseline' }}>
                  <label>Status</label>
                  <FormItem name="alertEnabled" valuePropName="checked" initialValue={status}>
                    <Switch defaultChecked={status} checked={status} onClick={(value) => setFormField('alertEnabled', (value) ? true : false)} />
                  </FormItem>
                </Row>
              </Col>
            </Row>
            {/* --------------------machine and status end--------------------------------- */}

            {/* Conditional Rendering if temp, rms, fft vibration */}
            {alertTypes.includes(typeAlert) && (
              <Row>
                <Col xs={24} sm={24} md={12}>
                  <FormItem 
                    // rules={[{ required: true, message: "Required" }]} 
                    name='alertSettingFrom'
                    label={<Space>
                        <label>Min</label>
                        <Typography.Text type="secondary">{` (${typeAlert})`}{returnUnit(typeAlert)}</Typography.Text>

                    </Space>}
                  >
                    <InputNumber onChange={(value) => alertSettings.from = value } style={{ width: "90%" }} />

                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <FormItem 
                    // rules={[{ required: true, message: "Required" }]} 
                    name='alertSettingTo'
                    label={<Space>
                        <label>Max</label>
                        
                        <Typography.Text type="secondary">{` (${typeAlert}) `}{returnUnit(typeAlert)}</Typography.Text>
                    </Space>}
                  >
                    <InputNumber onChange={(value) => alertSettings.to = value} style={{ width: "90%" }} />
                  </FormItem>
                </Col>
              </Row>

            )}
            {/*--------------------------- Conditional Rendering if temp, rms, fft vibration ----------------------*/}
            {/* Email part start */}
            <FormItem
              label={
                <Space>
                  <label>{`Email Addresses`}</label>
                  <Typography.Text type="secondary">{` (comma seperated)`}</Typography.Text>
                </Space>
              } name="to">
              <Select
                style={{
                  width: '100%',
                  border: '1px solid #E6E7E9'
                }}
                onChange={(value) => toEmailChange(value.map(v => v.toLowerCase()))}
                bordered={true}
                mode="tags" tokenSeparators={[',', ' ']}>
                {children}
              </Select>
            </FormItem>
            <FormItem
              label={
                <Space>
                  <label>{`CC: Email Addresses`}</label>
                  <Typography.Text type="secondary">{` (comma seperated)`}</Typography.Text>
                </Space>
              } name="cc">
              <Select
                style={{
                  width: '100%',
                  border: '1px solid #E6E7E9'
                }}
                onChange={(value) => ccEmailChange(value.map(v => v.toLowerCase()))}
                bordered={true}
                mode="tags" tokenSeparators={[',', ' ']}>
                {children}
              </Select>
            </FormItem>
            <FormItem
              label={
                <Space>
                  <label>{`BCC: Email Addresses`}</label>
                  <Typography.Text type="secondary">{` (comma seperated)`}</Typography.Text>
                </Space>
              } name="bcc">
              <Select
                style={{
                  width: '100%',
                  border: '1px solid #E6E7E9'
                }}
                onChange={(value) => bccEmailChange(value.map(v => v.toLowerCase()))}
                bordered={true}
                mode="tags" tokenSeparators={[',', ' ']}>
                {children}
              </Select>
            </FormItem>
            <FormItem
              label={
                <Space>
                  <label>{`Subject`}</label>
                  {//<Typography.Text type="secondary">{` (Template subject will be shared if blank)`}</Typography.Text>
              }
                </Space>
              } name="subject">
              <Input.TextArea />
            </FormItem>
            <FormItem
              label={
                <Space>
                  <label>{`Content`}</label>
                  {//<Typography.Text type="secondary">{` (Template subject will be shared if blank)`}</Typography.Text>
              }
                </Space>
              } name="content">
              <Input.TextArea />
            </FormItem>
            <Form.Item name="submit">
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "80%",
                  marginBottom: "0px",
                              }}
                  
              >
                {btnText}
              </Button>
            </Form.Item>
          </div>
        )}
      </Form>
    </Modal >
  );
};

export default AddAlertForm;
