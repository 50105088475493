import { notification } from 'antd';
import { list as listHealth} from '../services/apiInteraction'

const namespace = 'healthState'
export const list = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_HEALTH", payload: true });
            let data = await listHealth({ module: namespace })
            dispatch({ type: "LIST_HEALTH", payload: data });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_HEALTH", payload: false });
            notification.error({ message: err.message })
        }
    };
};
