import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./health.css";
import {
  Layout,
  Input,
  Row,
  Col,
  Button,
  Tooltip,
  Popconfirm,
  Tag,
  Select,
  Card,
  Table,
} from "antd";
import ActivityLog from "../../Component/ActivityLog/activityLog";
import * as AlertActivityActions from "../../actions/alertactivityActions";
import * as ActivityChatActions from "../../actions/activitychatActions";
//import AddCommentForm from "../../Component/Forms/AddCommentForm/AddCommentForm";
//import AddFindingForm from "../../Component/Forms/AddFindingForm/AddFindingForm";
import Addactivity from "../../Component/addactivity/addactivity";
import { AlertOutlined, EditOutlined, DeleteOutlined, TeamOutlined, PlusCircleOutlined } from "@ant-design/icons";
// import component
import HeaderTop from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";
import AddactivityModal from "../../Component/addactivity/addactivity";
//actions
import * as HealthActions from "../../actions/healthActions";
//import * as MachineActions from "../../actions/machineActions";
import { bindActionCreators } from "redux";
// import images
const onSearch = (value) => console.log(value);
const { Search } = Input;
export const Health = ({ alertActions, alerts, loading, machines, alertActivities, teams, healthState,healthActions }) => {

    let machineIds = undefined;
    const [machine, setMachine] = useState({});
    const [pageLoaded, setPageLoaded] = useState(false);
    const machineRef = useRef();
    machineRef.current = machine;
    const [isVisible, setIsVisible] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState(null);
    const [filteredAlertActivities, setFilteredAlertActivities] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [comment, setComment] = useState(false);
    const [finding, setFinding] = useState(false);
    //setFindingBox(false)
    const menu = machines.map((machine) => (
        <Select.Option key={machine.id}>
            <a>{machine.machine_name}</a>
        </Select.Option>
    ));

    const searchActivityLogs = (string) => {
        if (string) {
            let filtered = alertActivities.filter(
                (activity) =>
                    activity?.findings?.toLowerCase()?.includes(string.toLowerCase()) ||
                    activity?.recommended_action?.toLowerCase()?.includes(string.toLowerCase()) ||
                    activity?.machine_alert?.alert?.alert_type?.toLowerCase()?.includes(string.toLowerCase()) ||
                    activity.alert_urgency?.toLowerCase()?.includes(string.toLowerCase())
            );
            setFilteredAlertActivities(filtered);
        } else setFilteredAlertActivities([]);
        setSearchString(string);
    };
    useEffect(() => {
        searchActivityLogs(searchString);
    }, [alertActivities]);
    const setMachineAndAlerts = (machineId) => {
      healthActions.list();
        machineIds = machineId;
        setMachine(machines.find((machine) => machine.id == machineId));
    };

    useEffect(() => {
        if (Object.keys(machine).length === 0 && machines.length > 0) {
            let machineId = machines[0]?.id
            if (!pageLoaded) {
                let machineFound = machines.find(machine => machine.id == localStorage.getItem('machineId'))
                if (machineFound)
                    machineId = machineFound.id
            }
            setMachineAndAlerts(machineId);
            if (!pageLoaded)
                setPageLoaded(true)
        }

    }, [healthState]);

    const showcommentForm=()=> {
      if(comment===false){
        setComment(true)}
       /* if (comment == true) {
            return (
                <AddCommentForm>
                    visible={comment}
                    teams={teams}
                    onCancel={() => setIsVisible(false)}
                    onOk={() => setIsVisible(false)}
                </AddCommentForm>
                )
        }*/
    }
    //table columns
    const columns = [
        {
            title: "ALERT TYPE",
            dataIndex: "machine_alert",
            width: "20%",
            render: (record) => record = record.alert.alert_type.toString()
      
        
           // key: "alert.alert_type",
  

        },
        {
            title: "DATE & TIME",
            width: "20%",
            dataIndex: "created_at",
           // key: "created_at",
           
                //<p>{ alertActivities.machine_alert.alert}</p>
      },
      {
          title: "SEVERITY",
          width: "20%",
          dataIndex: "alert_urgency",
          key: "alert_urgency",
      },
      {
          title: "COMMENT",
          width: "20%",
          dataIndex: "marked_reason",
          key: "marked_reason",
          render: (text, record) => {
              return <Button
                  className=" center-align-button"
                  type="primary"
                  onClick={() => { setComment(true) }
                  }
              >
                 View Comment
              </Button>

              


          }
      },
      {
          title: "ASSIGNED TEAM",
          width: "20%",
          dataIndex: "team_id",
          key: "team_id",
         // render: (record) => record = record.alert.alert_type.toString()
      },
      {
          title: "FINDINGS",
          width: "20%",
          dataIndex: "remommended_action",
          key: "remommended_action",
          render: (text, record) => {
              return (
                  <PlusCircleOutlined onClick={() => { setFinding(true) }}>

                  </PlusCircleOutlined>
                  );
          }
          
      },
    {
      title: "STATUS",
      dataIndex: "activity_marked",
      key: "activity_marked",
      width: "70%",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Tag
            color={`${record.alert_enabled ? "success" : "error"}`}
            key={record.alert_enabled}
            style={{ marginTop: 5 }}
          >{`${record.alert_enabled ? "ACTIVE" : "IN-ACTIVE"}`}</Tag>
        </div>
      ),
     },

    {
      title: "ACTIONS",
      dataIndex: "actions",
      width: "10%",
      render: (text, record) => {
          return (
              <div>
            <AddactivityModal
              activity={
                  selectedActivityId &&
                  alertActivities.find((activity) => activity.id == selectedActivityId)
              }
        visibleModal={isVisible}
        teams={teams}
        onCancel={() => setIsVisible(false)}
        onOk={() => setIsVisible(false)}
           />
          
          <div style={{}}>
                      
                <Tooltip title={`Edit`}>
                    <EditOutlined
                              style={{ color: "#1E6EFE", marginRight: "10px" }}
                              type="link"
                              key={`edit-${record.id}`}
                              onClick={() => {
                                      setSelectedActivityId(record.id)
                                      setIsVisible(true)
                                  
                              }}
                    />
              
               </Tooltip>
            <Popconfirm
              key={`popconfirm-${record.id}`}
              title="Confirm delete?"
              onConfirm={() => deleteRecord(record)}
                >
               <Tooltip title={`Team`}>
                    <TeamOutlined
                        style={{ color: "#1E6EFE" }}
                        type="link"
                        
                    />
               </Tooltip>
                    
                    
              <Tooltip title={`Delete`}>
                <DeleteOutlined
                  style={{ color: "#1E6EFE" }}
                  type="link"
                  key={`delete-${record.id}`}
                />
               </Tooltip>

                </Popconfirm>
                
              </div>
              </div>
        );
      },
    },
  ];

  //states
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState("New");
  const [currentRecord, setcCurrentRecord] = useState(null);

  //CRUD functions
  const addRecord = () => {
    setcCurrentRecord(null)
    setMode('New')
    setIsVisible(!isVisible)
  }
    const editRecord = (record) => {
    setcCurrentRecord(record)
    setMode('Edit')
    setIsVisible(!isVisible)
  }

  const deleteRecord = (record) => {
      selectedActivityId.remove({ alertActivityId: record.id }, { machineId: machine.id })
  }

  const handleSave = (data) => {
    if (mode == 'New')
        alertActivities.create(data, { machineId: machine.id });
    else
      alertActivities.update(data, { machineId: machine.id });
    setVisible(false)
  }

  return (
    <div>
      <Layout>
        <HeaderTop />
      </Layout>
      <Layout>
        <Sidebar />
        <div
          style={{ width: "100%" }}
          className="site-layout responsive-margin-60"
        >
          <Layout
            className="site-layout-background"
            style={{
              margin: "24px 16px 0px 16px",
              background: "none",
            }}
          >
            <Row
              style={{
                width: "98%",
                paddingLeft: "2%",
                paddingRight: "0px",
              }}
            >
              <Col span={12}>
                <h2 className="heading-small">Activity Log</h2>
              </Col>
              <Col span={12}>
                <Search onChange={(ev) => searchActivityLogs(ev.target.value)} className="sidebar-searchbar" placeholder="Search Logs" />
              </Col>
              
            </Row>
          </Layout>
          <Card
            style={{
              width: "95%",
              margin: "auto",
            }}
            className="w-100"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
              }}
                      >
                          
              <label>{`Select Machine`}</label>
              <Select
                placeholder="Select Machine"
                style={{
                  background: "white",
                  width: 238,
                  marginLeft: "10px",
                  border: "1px solid #E6E7E9",
                }}
                value={machine.id}
                onChange={(value) => setMachineAndAlerts(value)}
                bordered={true}
              >
                {menu}
              </Select>
            </div>
                      
            <Table
              loading={loading}
              scroll={{ x: 400 }}
              dataSource={healthState}
              columns={columns}
            />
                  </Card>

              </div>
          
              
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state.healthReducer.list);
  return {
    alerts: state.alertReducer.list,
    loading: state.alertReducer.loading,
    machines: state.machineReducer.list,
    alertActivities: state.alertactivityReducer.list,
    teams: state.teamReducer.list,
    healthState:state.healthReducer.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    healthActions: bindActionCreators(HealthActions, dispatch),
      //machineActions: bindActionCreators(MachineActions, dispatch),
      activityChatActions: bindActionCreators(ActivityChatActions, dispatch),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Health);
