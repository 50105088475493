import React, { useState } from 'react';
import { Modal, Form, Input, Spin, Button } from 'antd';
import {
    EyeTwoTone,
    EyeInvisibleOutlined
} from "@ant-design/icons";

const ChangePasswordForm = (props) => {
    const {
        visible,
        loading,
        closable,
        onCancel,
        changePassword
    } = props;
    const [btnDisable,setBtnDisable]=useState(true)
    const [form] = Form.useForm();
    let resetPassword = async () => {
        try {
            let values = await form.validateFields()
            changePassword(values?.password)
            form.resetFields();
            onCancel()
        }
        catch (err) {
            let errors = '';
            err.errorFields.forEach((er) => {
                if(errors !='')
                errors = errors + '\n\n'; 
                errors =errors + er.errors[0] ;
                
              });
            //alert(errors);
        }
    }
    let checkConfirm = (rule, value, callback) => {
        if (form.getFieldValue('password') === "" || form.getFieldValue('confirm') === ""){
            setBtnDisable(true)
        }

        else if ((rule.field === 'confirm' && form.getFieldValue('password') !== form.getFieldValue('confirm')) && form.getFieldValue('confirm') !== "") {
            callback('Password do not match');
            //setBtnDisable(false)
            setBtnDisable(true)
        }
       
        else if (rule.field === 'password'  && value !== form.getFieldValue('confirm') && form.getFieldValue('confirm')!=="") {
            
            //callback('Password must have a min length of 8 characters including 1 special character, 1 uppercase and 1 digit');
            //setBtnDisable(false)
            
            callback('');
            setBtnDisable(true)
        } 
        else {
            setBtnDisable(false)
            callback();
        }
    };
    return (
        <Modal
            title={'Change Password'}
            visible={visible}
            closable={closable}
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            footer={
                loading ? (
                    <Spin />
                ) : (
                    <Button onClick={resetPassword} disabled={btnDisable} type="primary">
                        Submit
                    </Button>
                )
            }
        >
            <Form form={form}
                layout="vertical"
                hideRequiredMark={true}
                name="form_in_modal">
                <Form.Item rules={[
                    { required: true, message: "Password must have a min length of 8 characters including 1 special character, 1 uppercase and 1 digit", pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ },
                    {
                        validator: checkConfirm,
                    },
                ]} name="password" label={'New Password'} >
                    <Input.Password
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message: 'Please confirm your password',
                    },
                    {
                        validator: checkConfirm,
                    },
                ]} label={`Confirm Password:`} name="confirm" >
                    <Input type="password"/>
                </Form.Item>
            </Form>
        The system will take your email address only for the creation of your ID no other personal information will be captured by the system.
        </Modal>
    );

}

export default ChangePasswordForm

