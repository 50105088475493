const initialState = {
    chart: ""
}

const chartReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SHOW_CHART_DATA":
            return {
                ...state,
                chart: action.payload,
            };

        default:
            return state;
    }
};

export default chartReducer;