import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./roles.css";
import { Layout, Row, Col, Button, Tooltip, Tag, Popconfirm } from "antd";
import { AlertOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

// import component
import HeaderTop from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";
import TableRole from "../../Component/tableRole/tableRole";
import AddRoleForm from "../../Component/Forms/AddRoleForm/AddRoleForm";

//actions
import * as UserActions from "../../actions/userActions";
import * as RoleActions from "../../actions/roleActions";
import * as SignalRActions from "../../actions/signalRActions";
import * as AuthActions from "../../actions/authActions";

import { bindActionCreators } from "redux";

export const Roles = ({
  roleActions,
  role,
  roles,
  loading,
  signalRActions,
  isSuperAdmin
}) => {
  const columns = [
    {
      title: "ROLE NAME",
      dataIndex: "role_name",
      key: "role_name",
    },
    {
      title: "CAPABILITIES",
      dataIndex: "capabilites",
      key: "capabilites",
      width: "70%",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            // flexDirection: "column",
          }}
        >
          {record.capabilites.map((capability) => (
            <Tag
              key={capability}
              style={{ marginTop: 5 }}
            >{`${capability}`}</Tag>
          ))}
        </div>
      ),
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      width: "10%",
      render: (text, record) => {
        return (
          // (role) ? (<Tag color="orange">Read only</Tag>) :
          //     (
          <div style={{}}>
            <Tooltip title={`Edit`}>
              <EditOutlined
                style={{ color: "#1E6EFE", marginRight: "10px" }}
                type="link"
                key={`edit-${record.id}`}
                onClick={() => {
                  editRecord(record);
                }}
              />
            </Tooltip>

            <Popconfirm
              key={`popconfirm-${record.id}`}
              title="Confirm delete?"
              onConfirm={() => deleteRecord(record)}
            >
              <Tooltip title={`Delete`}>
                <DeleteOutlined
                  style={{ color: "#1E6EFE" }}
                  type="link"
                  key={`delete-${record.id}`}
                />
              </Tooltip>
            </Popconfirm>
          </div>
          // )
        );
      },
    },
  ];

  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState("New");
  const [currentRecord, setcCurrentRecord] = useState(null);
  const addRecord = () => {
    setcCurrentRecord(null);
    setMode("New");
    setVisible(!visible);
  };
  const editRecord = (record) => {
    setcCurrentRecord(record);
    setMode("Edit");
    setVisible(!visible);
  };
  const openModal = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    roleActions.list();
    return () => {
      signalRActions.leaveGroup(["user", "role"].map((group) => ({ group })));
    };
  }, []);

  const getRoles = () => {
    return roles;
  };

  const handleSave = (data) => {
    mode == "New" ? roleActions.create(data) : roleActions.update(data);
    setVisible(false);
  };

  const deleteRecord = (data) => {
    roleActions.remove({ roleId: data.id });
  };

  return (
    <div>
      <Layout>
        <HeaderTop />
      </Layout>
      <Layout>
        <Sidebar />
        <div style={{ width: "100%" }} className="site-layout">
          <Layout
            className="site-layout-background"
            style={{
              margin: "24px 16px 0px 16px",
              background: "none",
            }}
          >
            <Row
              style={{
                width: "98%",
                paddingLeft: "2%",
                paddingRight: "0px",
              }}
            >
              <Col span={12}>
                <h2 className="heading-small">Roles</h2>
              </Col>
              {
                // !(role && role.role && role.role.capabilites && role.role.capabilites.includes('Add Roles')) &&
                <Col span={12}>
                  <Button
                    className=" right-align-button"
                    icon={<AlertOutlined />}
                    type="primary"
                    onClick={addRecord}
                  >
                    ADD ROLE
                  </Button>
                </Col>
              }
              <AddRoleForm
                onCreate={(data) => {
                  handleSave(data);
                }}
                type={mode}
                isSuperAdmin={isSuperAdmin}
                record={currentRecord && currentRecord}
                visible={visible}
                onCancel={() => {
                  setVisible(false);
                }}
              />
            </Row>
          </Layout>
          <TableRole
            loading={loading}
            columns={columns}
            dataSource={getRoles()}
          />
        </div>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roles: state.roleReducer.list,
    role: state.roleReducer.current,
    loading: state.roleReducer.loading,
    isSuperAdmin: state.authReducer.isSuperAdmin,
    loggedIn: state.authReducer.loggedIn,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    roleActions: bindActionCreators(RoleActions, dispatch),
    signalRActions: bindActionCreators(SignalRActions, dispatch),
    actions: bindActionCreators(AuthActions, dispatch),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
