import { notification } from 'antd';
import { list as listMachines, create as createMachine, update as updateMachine } from '../services/apiInteraction'

const namespace = 'machines'
export const list = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_MACHINE", payload: true });
            let data = await listMachines({ module: namespace })
            dispatch({ type: "LIST_MACHINE", payload: data });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_MACHINE", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const create = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_MACHINE", payload: true });
            await createMachine({ module: namespace, data })
            dispatch({ type: "SET_LOADING_MACHINE", payload: false });
            notification.success({ message: 'Machine Creation Successful' })
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_MACHINE", payload: false });
            notification.error({ message: err.message })
        }
    };
};

export const update = (data, conditions) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_MACHINE", payload: true });
            await updateMachine({ module: namespace, data, stringifyJson: false, conditions })
            notification.success({ message: 'Machine Updated Successfully' })
            dispatch({ type: "SET_LOADING_MACHINE", payload: false });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_MACHINE", payload: false });
            notification.error({ message: err.message })
        }
    };
};