import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./users.css";
import { Layout, Row, Col, Button, Tooltip, Popconfirm, Tag } from "antd";

import {
  AppstoreAddOutlined,
  UserAddOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

// import component
import HeaderTop from "../../Component/Header/Header";
import Sidebar from "../../Component/Sidebar/Sidebar";
import StandardTable from "../../Component/Table/table";
import AddUserForm from "../../Component/Forms/AddUserForm/AddUserForm";
import AddTeamForm from "../../Component/Forms/AddTeamForm/AddTeamForm";

import * as UserActions from "../../actions/userActions";
import * as RoleActions from "../../actions/roleActions";
import * as TeamActions from "../../actions/teamActions";
import * as SignalRActions from "../../actions/signalRActions";
import { bindActionCreators } from "redux";
import machines from "../machines/machines";

export const Users = ({
  userActions,
  roleActions,
  teamActions,
  users,
  roles,
  teams,
  loading,
  machines,
  signalRActions,
}) => {
  //columns
  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "EMAIL ADDRESS",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "MACHINES",
      dataIndex: "userMachines",
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            {record.userMachines && record.userMachines.map((machine) => <Tag key={machine.id} style={{ marginTop: 5 }} > {`${machine.machine_name}`}</Tag>)}
          </div >)
      }
    },
    {
      title: "ROLE",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "ACTIONS",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <div style={{}}>
            <Tooltip title={`Edit`}>
              <EditOutlined
                style={{ color: "#1E6EFE", marginRight: "10px" }}
                type="link"
                key={`edit-${record.id}`}
                onClick={() => {
                  editUser(record);
                }}
              />
            </Tooltip>

            <Popconfirm
              key={`popconfirm-${record.id}`}
              title="Confirm delete?"
              onConfirm={
                () => deleteRecord(record)
              }
            >
              <Tooltip title={`Delete`}>
                <DeleteOutlined
                  style={{ color: "#1E6EFE" }}
                  type="link"
                  key={`delete-${record.id}`}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  //User Modal
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState("New");
  const [currentRecord, setcCurrentRecord] = useState(null);

  const addUser = () => {
    setcCurrentRecord(null);
    setMode("New");
    setVisible(!visible);
  };

  const editUser = (record) => {
    setcCurrentRecord(record);
    setMode("Edit");
    setVisible(!visible);
  };

  const deleteRecord = (data) => {
    userActions.remove({ email: data.email });
  };

  const handleSave = async (data) => {
    (mode == 'New') ? userActions.create(data) : userActions.update(data)
    setVisible(false)
  }

  //Team Modal
  useEffect(() => {
    roleActions.list();
    userActions.list();
    teamActions.list();
    return () => {
      signalRActions.leaveGroup(["user", "role"].map((group) => ({ group })));
    };
  }, []);

  const [visibleTeam, setVisibleTeam] = useState(false);
  const addTeam = () => {
    setVisibleTeam(!visibleTeam);
  };
  const getUsers = () => {
    let number = 0;
    return (
      users &&
      users.map((user) => {
        number++;
        let role = roles.find((role) => role.id == user.role_id);
        let machineIds = user.Machine_Users.map(ele => ele.machine_id);
        let userMachines = (machines.filter(machine => machineIds.includes(machine.id)))
        let teamIds = user.Team_Users.map(ele => ele.team_id);
        let teamUsers = (teams.filter(team => teamIds.includes(team.id)))
        return {
          number,
          name: user.full_name,
          email: user.email,
          role,
          roleName: role ? role.role_name : "",
          machineIds,
          userMachines: userMachines,
          teamIds,
          teamUsers
        };
      })
    );
  };
  return (
    <div>
      <Layout>
        <HeaderTop />
      </Layout>
      <Layout>
        <Sidebar />
        <div style={{ width: "100%" }} className="site-layout">
          <Layout
            className="site-layout-background  responsive-margin-70"
            style={{
              margin: "24px 16px 0px 16px",
              background: "none",
            }}
          >
            <Row
              style={{
                width: "98%",
                paddingLeft: "2%",
                paddingRight: "0px",
              }}
            >
              <Col span={12}>
                <h2 className="heading-small">USERS</h2>
              </Col>
              <Col span={12}>
                <Button
                  className="width-190 right-align-button ml-1 responsive-button"
                  icon={<UserAddOutlined />}
                  type="primary"
                  onClick={addUser}
                >
                  ADD USER
                </Button>
                <AddUserForm
                  onCreate={(data) => {
                    handleSave(data);
                  }}
                  machines={machines}
                  roles={roles}
                  teams={teams}
                  type={mode}
                  record={currentRecord && currentRecord}
                  visible={visible}
                  onCancel={() => {
                    setVisible(false);
                  }}
                />
              </Col>
            </Row>
          </Layout>
          <StandardTable
            loading={loading}
            columns={columns}
            dataSource={getUsers()}
          />
        </div>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.userReducer.list,
    roles: state.roleReducer.list,
    machines: state.machineReducer.list,
    teams: state.teamReducer.list,
    loading: state.userReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    teamActions: bindActionCreators(TeamActions, dispatch),
    roleActions: bindActionCreators(RoleActions, dispatch),
    signalRActions: bindActionCreators(SignalRActions, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
