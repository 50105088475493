import { notification } from 'antd';
import { list as listSensorValues, get as getSensorValue } from '../services/apiInteraction'

const namespace = 'sensorvalues'
export const list = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_SENSORVALUE", payload: true });
            let data = await listSensorValues({ module: namespace })
            dispatch({ type: "LIST_SENSORVALUE", payload: data });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_SENSORVALUE", payload: true });
            notification.error({ message: err.message })
        }
    };
};

export const get = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_LOADING_SENSORVALUE", payload: true });
            let data = await getSensorValue({ module: namespace })
            dispatch({ type: "GET_SENSORVALUE", payload: data });
        }
        catch (err) {
            dispatch({ type: "SET_LOADING_SENSORVALUE", payload: false });
            notification.error({ message: err.message })
        }
    };
};
