import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import { connect } from "react-redux";
import * as SideBarActions from "../../actions/sideBarActions";
import * as AuthActions from "../../actions/authActions";
import { bindActionCreators } from "redux";
import "../../../src/actions/authActions";
import "../../router/routers";
import moment from "moment";
import ChangePasswordForm from "../Forms/ChangePasswordForm";
// import images
import userImage from "../../assets/images/user-image.jpg";
import Logo from "../../assets/images/logo-black.png";
import {
  MenuOutlined,
  DownOutlined,
  LogoutOutlined,
  LockOutlined,
  BellOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Select,
  Button,
  Dropdown,
  message,
  Badge,
  Row,
  Col,
} from "antd";
const { Option } = Select;
const { Header, Content, Sider } = Layout;

// Drop Down
// function handleChange(value) {
//   console.log(`selected ${value}`);
// }
export const HeaderTop = ({
  sidebarActions,
  authActions,
  visible,
  role,
  alertActivities,
  machines,
}) => {
  const [notificationsSyncedAt, setNotificationsSyncedAt] = useState(
    localStorage.getItem("notificationsSyncedAt")
  );
  const [isPasswordChangeModalVisible, setIsPasswordChangeModalVisible] =
    useState(false);
  const toggleSideBar = () => {
    sidebarActions.toggleSideBar(!visible);
  };
  const getSortedActivities = () => {
    return alertActivities.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
  };

  const getBadgeCount = () => {
    if (!notificationsSyncedAt) return alertActivities.length;
    let temp = alertActivities.filter(
      (activity) =>
        new Date(activity?.created_at).getTime() >
        parseInt(notificationsSyncedAt)
    );
    return temp.length;
  };

  const [showMore, setshowMore] = useState(5);

  function showMoreButton() {
    // setshowMore = setshowMore + 5;
    // return showMore;
  }

  const menu = (
    <Menu style={{ marginRight: "10px", marginTop: "10px", width: "100%" }}>

      <Menu.Item key="0">
        <a onClick={() => setIsPasswordChangeModalVisible(true)} href="#">
          <LockOutlined />
          Change Password
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a
          onClick={() => authActions.signOut()}
          icon={<LogoutOutlined />}
          href="#"
        >
          <LogoutOutlined />
          Log Out
        </a>
      </Menu.Item>
    </Menu>
  );
  const currentDateTime = Date();
  const menuNotification = (
    <Menu
      className="notification-width"
      // style={{ border: "1px solid #caced4" }}
    >
      <Row className="border-bottom-notification">
        <h2 className="b-color notification-heading">Alerts</h2>
      </Row>
      {getSortedActivities()
        .slice(0, 10)
        .map((activity) => {
          const machine_alert = activity?.machine_alert?.alert;
          const { to, from, duration } = machine_alert.alert_settings;
          const machineName =
            machines.find((machine) => machine.id == activity?.machine_id)
              ?.machine_name || activity?.machine_id;
          const formattedTime = moment(activity?.created_at)?.format(
            "dddd [at] h:mm A"
          );
          return (
            <Menu.Item
                  style={{ borderBottom: "1px solid #caced4", backgroundColor: "white" }}
              getPopupContainer={() => document.getElementById("area")}
            >
              <a
                style={{
                  marginTop: 3,
                  marginBottom: 3,
                }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.antgroup.com"
              >
                <Row>
                  <Col span={3}>
                    <div className="badge-notifications">
                      <p>{machineName.charAt(0)}</p>
                    </div>
                  </Col>
                  <Col span={16}>{machineName}</Col>
                </Row>
                <Row>
                  <Col span={3}></Col>
                  <Col span={4}>
                    <p className="date-notification">{formattedTime}</p>
                  </Col>
                  <Col span={6}></Col>
                </Row>

                {/* {`${machineName} Alert(${formattedTime}): ${
                machine_alert?.alert_type
              } in range ${from} - ${to} for ${duration || 0} minutes.`} */}
              </a>
            </Menu.Item>
          );
        })}
      <Row
        style={{ paddingTop: 10, paddingBottom: 10 }}
        className="border-bottom-notification"
      >
        <Col span={10}>
          <a
            onClick={(e) => (e.preventDefault, setshowMore(showMore + 5))}
            style={{ paddingLeft: 20 }}
            className="b-color"
          >
            View all...
          </a>
        </Col>
        <Col span={2}></Col>
        <Col span={4}></Col>
        <Col span={8}>
          <a href="#" className="b-color">
            Preferences
          </a>
        </Col>
      </Row>
    </Menu>
  );
  const handleCancel = () => {
    setIsPasswordChangeModalVisible(false);
  };

  return (
    <div>
      <Layout style={{ padding: 0, margin: 0 }}>
        <ChangePasswordForm
          changePassword={authActions?.changePassword}
          onCancel={handleCancel}
          visible={isPasswordChangeModalVisible}
        />
        <Header style={{ padding: 0, margin: 0 }} className="header">
          <Link to="/">
            <img className="logo-image" src={Logo} />
          </Link>

          <Menu
            style={{
              float: "right",
              width: "250px",
              paddingLeft: "5px",
            }}
            mode="horizontal"
            defaultSelectedKeys={["2"]}
          >
            <img className="user-image" src={userImage} />
            <Menu.Item style={{ background: "#E8F0FF " }} key="1">
              {role.full_name || "Super Admin"}
            </Menu.Item>

            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <DownOutlined />
              </a>
            </Dropdown>
          </Menu>
          <Link className="bell-icon-header">
            <Dropdown.Button
              style={{ marginTop: 20,float: "right" }}
              onVisibleChange={(ev) => {
                if (ev) {
                  let list = getSortedActivities();
                  if (Array.isArray(list) && list[0]?.created_at) {
                    let dateTime = new Date(list[0].created_at).getTime();
                    if (dateTime) {
                      localStorage.setItem("notificationsSyncedAt", dateTime);
                      setNotificationsSyncedAt(dateTime);
                    }
                  }
                }
              }}
              className="noborder-dropdown"
              overlay={menuNotification}
              trigger={["click"]}
              onClick={(e) => e.preventDefault()}
              // placement="bottomCenter"
              icon={
                <BellOutlined
                  style={{
                    fontSize: "22px",
                  }}
                />
              }
            >
              <Badge className="badge" count={getBadgeCount()}></Badge>
            </Dropdown.Button>
          </Link>
          <Button
            className="sidebar-move"
            type="primary"
            icon={<MenuOutlined />}
            onClick={toggleSideBar}
          />
        </Header>
      </Layout>
    </div>
  );
};
const mapStateToProps = (state) => ({
  machines: state.machineReducer.list,
  visible: state.sideBarReducer.visible,
  role: state.roleReducer.current,
  alertActivities: state.alertactivityReducer.list,
});

const mapDispatchToProps = (dispatch) => ({
  sidebarActions: bindActionCreators(SideBarActions, dispatch),
  authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop);
